import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from '~/common/hooks/useTranslation';
import { LayoutCommon } from '~/styles/genaralStyled';
import CardInfo from './components/CardInfo';
import { Map } from './components/Map';
import { ContactUsWrapper } from './styled';

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <ContactUsWrapper>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/aboutUs/AboutUs.png)`
        }}
        className='bg-top'
      >
        <LayoutCommon>
          <div className='header '>{t('layout.navBar.contactUs')}</div>
        </LayoutCommon>
      </div>
      <LayoutCommon>
        <Row gutter={24} className='box-contact'>
          <Col xl={12} md={12} sm={24} xs={24}>
            <CardInfo t={t} />
          </Col>
          <Col xl={12} md={12} sm={24} xs={24}>
            <Map />
          </Col>
        </Row>
      </LayoutCommon>
    </ContactUsWrapper>
  );
};

export default ContactUs;
