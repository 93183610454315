import { gql } from '@apollo/client';

export const CREATE_CONTACT = gql`
  mutation Mutation($input: CreateContactUsInput!) {
    createContactUs(input: $input) {
      id
      name
      country
      email
      phoneNo
      company
      role
      inquiry
      replied
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_SUBSCRIBER = gql`
  mutation Mutation($input: createSubscriberInput!) {
    createSubscriber(input: $input) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
