import React from 'react';
import { CaseStudyWrapper } from './styled';
import { LayoutCommon } from '../../styles/genaralStyled';
import Portfolio from './Portfolio';
// import ClientMind from '../HomePage/components/ClientMind';
// import OurTeams from '../AboutUs/OurTeams';
import { useTranslation } from '~/common/hooks/useTranslation';

const CaseStudy = () => {
  const { t } = useTranslation();
  return (
    <CaseStudyWrapper>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/aboutUs/AboutUs.png)`
        }}
        className='bg-service'
      >
        <LayoutCommon>
          <div className='main-title md:pt-[120px]'>
            {t('caseStudy.portfolio.title')}
          </div>
        </LayoutCommon>
      </div>
      <Portfolio t={t} />
      {/* <ClientMind t={t} /> */}
      {/* <OurTeams t={t} /> */}
    </CaseStudyWrapper>
  );
};

export default CaseStudy;
