export const services = [
  {
    category: 'Gaming Software Development',
    technical: [
      'Capable of developing diverse types of games encompassing 2D, 3D, hardcore, traditional narrative games, and blockchain-based games, full-motion animation, 2D & 3D graphic design, and rendering.',
      'Craft custom graphics, high-quality games, original storylines, characters, levels, physics, logic, and sound effects.'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/gamingSoftware.png'
  },
  {
    category: 'AR & VR Game Development',
    technical: [
      'Provide complete AR & VR game solutions.',
      'Provide Engineering tailored software for diverse platforms.'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/VR.png'
  },
  {
    category: 'Metaverse Game Development',
    technical: [
      'Metaverse Games like Play-to-earn and Move-to-earn.',
      'Social Gaming.',
      'Avatar Creation.',
      'Blockchain Integration.',
      'NFT integration, custom tokens.'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/metavarseGame.png'
  },
  {
    category: 'AI Game Development',
    technical: [
      'Our team of AI developers can shape NPC behaviours using AI to generate realistic events and interactions, making the game feel more unpredictable and exciting.'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/AIGame.png'
  },
  {
    category: 'Game Design',
    technical: [
      'During this phase, we create foundational concept art for characters, environments, and in-game elements as outlined in the Game Design Document (GDD). This aids in visualizing these aspects in 3D and ensures clarity for subsequent processes.'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/GameDesign.png'
  },
  {
    category: 'Unity Game Development',
    technical: [
      "Utilizing the Unity engine's cross-platform capability and diverse asset store, we craft multiplayer games compatible with desktops, mobile devices, consoles, and various other platforms.",
      'Unity Engine 2D/3D Modeling.',
      'Unity Engine 2D/3D Animation.',
      'Unity Pipeline Integration.',
      'Unity Cross-platform Gaming.'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/UnityGame.png'
  }
];

export const advantages = [
  {
    title: 'Competitive Cost',
    des: [
      'Cost-efficient',
      'Delivered game projects at 40% of the anticipated cost'
    ]
  },
  {
    title: 'High-quality Solutions',
    des: [
      'Provide cutting-edge game development solutions of the utmost quality',
      'Utilize top-tier talent and the latest approaches, employing leading technology and best practices to create games that make a lasting impression'
    ]
  },
  {
    title: 'Full Of Services',
    des: [
      'Offer a comprehensive range of services, spanning from 3D and 2D game development to art production and robust post-release support',
      'Deliver all-encompassing solutions in one place, empowering you to launch your game ahead of competitors'
    ]
  }
];
