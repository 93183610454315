import styled from 'styled-components';

export const ExpertiseWrapper = styled.div`
  .bg-expertises {
    height: 100px;
    padding: 80px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  @media screen and (min-width: 768px) {
    .bg-expertises {
      height: 400px;
    }
  }
`;

export const SubExpertiseWrapper = styled.div`
  padding-top: 30px;
  .capability {
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ff5300;
    margin-top: 20px;
    display: inline;

    &-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #373737;
      margin-top: 15px;
    }

    &-main {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7b7b7b;
      margin: 30px 0;
      text-align: justify;
    }
  }

  .capability::after {
    position: absolute;
    content: '';
    width: 40px;
    display: block;
    border-bottom: 1px solid rgb(255, 83, 0);
    top: 50%;
    right: -50px;
    height: 1px;
  }

  @media screen and (min-width: 768px) {
    padding: 60px 0;
    .capability {
      &-title {
        font-size: 36px;
        line-height: 58.59px;
      }
    }
  }
`;

export const TechnologyBoxWrapper = styled.div`
  padding-bottom: 60px;
  .tech__item:hover {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    .tech__item_ball {
      transform: scale(1.1);
      transition: 0.5s;
    }
  }
`;

export const TechStackBoxWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 60px;

  .tech-category {
    ul {
      display: flex;
      justify-content: center;
    }
  }

  .items__tech {
    background: rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.12);
  }

  .items__tech:hover {
    color: #ff5400;
  }

  .active {
    color: #ff5400;
  }

  .items__tool:hover {
    img {
      transform: scale(1.2);
      transition: 0.5s;
    }
  }
`;

export const DevelopmentFieldsWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 60px;

  .items__tech {
    background: rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.12);
  }

  .items__tech:hover {
    color: #ff5400;
  }

  .active {
    color: #ff5400;
  }

  .items__tool:hover {
    img {
      transform: scale(1);
      transition: 0.5s;
    }
  }
`;
