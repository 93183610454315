import React, { Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN_KEY } from '~/constants/init.constants';
import { ROUTE_PATH } from '~/routes/route.constant';

const AuthLayout = () => {
  const navigate = useNavigate();
  const isLogin = localStorage.getItem(ACCESS_TOKEN_KEY);

  useEffect(() => {
    if (isLogin) return navigate(ROUTE_PATH.BLOG_MANAGEMENT);
  }, []);
  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  );
};

export default AuthLayout;
