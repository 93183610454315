import antdViVN from 'antd/es/locale/vi_VN';
import viMsg from '../locales/vi_VN.json';
import { flattenMessages } from '~/helpers/flatten';

const ViLang = {
  antd: antdViVN,
  locale: 'vi-VN',
  messages: {
    ...flattenMessages(viMsg)
  }
};
export default ViLang;
