/* eslint-disable react/display-name */
import React from 'react';
import SvgIcon from '../SvgIcon';
import PropTypes from 'prop-types';
import { ButtonStyled } from './styled';

const Button = React.memo((props) => {
  const { color, className } = props;
  return (
    <ButtonStyled
      {...props}
      className={`${className} relative rounded px-5 py-2.5 overflow-hidden group hover:bg-gradient-to-r 
       hover:!text-white hover:ring-2 hover:ring-offset-2 
       transition-all ease-out duration-300 ${
         color
           ? 'hover:!bg-[#0085D6] hover:from-[#0085D6] hover:to-[#0085D6] hover:ring-[#0085D6]'
           : 'hover:!bg-[#FF5400] hover:from-[#FF5400] hover:to-[#FF8A00] hover:ring-[#FF5400]'
       }`}
    >
      <span className='absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease'></span>
      <div className='btn-name'>{props.children}</div>
      <SvgIcon
        name={`${
          props.name
            ? props.name
            : props.backgroundColor
            ? 'icon-next-blue'
            : 'icon-next'
        }`}
      />
    </ButtonStyled>
  );
});

Button.propTypes = {
  children: PropTypes.object,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string
};

// Button.defaultProps = {
//   backgroundColor: 'linear-gradient(90deg, #FF5400 0%, #FF8A00 100%);'
// };

export default Button;
