import styled from 'styled-components';

export const AnonymousWrapper = styled.div`
  .sticky-footer {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    top: 100px;
  }

  .bt-footer {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 10px 0;
    color: #fff;
    border-top: 1px solid gray;
  }

  .top-80 {
    position: relative;
    top: -82px;
  }
`;

export const ContactWrapper = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }

  height: 48px;
  background: linear-gradient(90deg, #ff5400 0%, #ff8a00 100%),
    linear-gradient(90deg, #0085d6 0%, #006fd6 100%);

  p {
    color: #fff;
  }

  .header-page {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: 1200px) {
    .header-page {
      padding: 0 20px;
    }
  }
`;

export const MenuWrapper = styled.div`
  position: sticky;
  top: -49px;
  z-index: 99;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .logo-company {
    height: 54px;
  }

  /* ${({ isActive }) =>
    isActive &&
    `animation-name: example;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    box-shadow: 0px 1px 10px 0px #0000001A;
    `}; */

  @keyframes example {
    from {
      background-color: unset;
    }
    to {
      background-color: #ffffff;
    }
  }

  .sticky-color {
    animation-name: color-button;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  .sticky-color {
    path {
      animation-name: color-svg;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
  }

  @keyframes color-button {
    from {
      color: #ffffff;
    }
    to {
      color: black;
    }
  }

  @keyframes color-svg {
    from {
      fill: #ffffff;
    }
    to {
      fill: black;
    }
  }

  a {
    cursor: pointer;
    text-decoration: unset;
    color: ${({ isActive }) => (isActive ? 'black' : 'black')};
  }

  .hamburger-menu {
    font-size: 30px;
    display: none;
  }

  /* .line-bottom::after {
    content: ' ';
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  } */

  ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin-bottom: 0;
  }

  .item-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 0 12px;
  }

  .nation {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    padding-left: 15px;
  }

  .ant-space {
    align-items: center;
  }

  .ant-input {
    border-radius: 20px;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0 26px 26px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-close {
    display: none;
  }

  .row-top {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }
  /* 
  .menu {
    display: flex;
    align-items: center;
    gap: 50px;
    position: relative;
    z-index: 99;
    color: #fff;
    height: 80px;
    width: 100%;
    justify-content: flex-end;
  } */

  .nav-elements {
    display: flex;
  }

  .input-search {
    width: 0;
    height: 0;
  }

  .icon-search:hover {
    /* position: absolute;
      top: 23px;
      width: 200px;
      left: 50%;
      transform: translateX(-50%); */

    .input-search {
      transition: width 0.4s ease-in-out;
      width: 200px;
      height: 30px;
    }
  }

  @media screen and (max-width: 1200px) {
    .row-top {
      padding: 0 20px;
    }
  }

  @media (max-width: 768px) {
    top: -1px;
    padding: 0;

    .active {
      a {
        color: black !important;
      }
    }

    .btn-close {
      display: block;
    }

    .nation {
      display: none;
    }

    .ant-space {
      justify-content: flex-end;
    }

    .hamburger-menu {
      display: block;
      height: 80px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #fff;
      margin-left: 20px;
    }

    .nav-elements {
      position: absolute;
      right: 0;
      top: 80px;
      background-color: #fff;
      width: 0px;
      height: 100vh;
      transition: all 0.2s ease-in;
      overflow: hidden;
    }

    .nav-elements.active {
      width: 50%;
    }

    .nav-elements ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .nav-elements ul li {
      margin-right: unset;
      margin-top: 22px;
    }
  }

  @media (max-width: 600px) {
    .row-top {
      padding: 0;
    }

    .menu-icon {
      display: block;
      cursor: pointer;
    }

    .nav-elements.active {
      width: 55%;
    }

    .logo-company {
      height: 44px;
    }

    .hamburger-menu {
      margin-left: 10px;
    }
  }
`;

export const FooterWrapper = styled.div`
  padding-bottom: 60px;

  .input-email {
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: relative;
    top: -75px;
    border-radius: 12px;
  }

  .key-option {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 26px;
  }

  .sub-option {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin: 10px 0;
  }
  .sub-option:hover {
    color: #ff8a00;
  }

  .text {
    &-1 {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }

    &-1::after {
      content: '';
      width: 40px;
      display: block;
      border-bottom: 1px solid #ffffff;
      position: relative;
      top: -9px;
      right: -172px;
    }

    &-2 {
      font-weight: 600;
      font-size: 28px;
      line-height: 59px;
      color: #ffffff;
    }
  }

  .context {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin: 20px 0;

    .p16 {
      padding: 8px 0;
    }
  }

  .followUs {
    margin-bottom: 16px;
    height: 30px;
    color: #ffffff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }

  .ant-btn-primary,
  .ant-input-search-button {
    height: 54px;
    background-color: #fff;
    padding: 0;
    padding-right: 4px;
  }

  .ant-input-affix-wrapper {
    height: 54px;
    border-radius: 80px 0 0 80px;
    :hover {
      border: 0;
    }
  }

  input.ant-input {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7b7b7b;
  }

  .ant-input-search-button,
  span.ant-input-group-addon,
  .ant-btn-primary,
  .ant-input-search-button {
    border-radius: 0 8px 8px 0;
  }

  .ant-input-search-button:hover {
    background-color: #fff;
  }

  //Ipad

  @media screen and (min-width: 768px) {
    .input-email {
      top: -100px;
      padding: 0 78px;
    }

    .text {
      &-1 {
        font-size: 16px;
        line-height: 24px;
      }

      &-2 {
        font-size: 39.0625px;
        line-height: 59px;
      }
    }
  }
`;
