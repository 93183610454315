import styled from 'styled-components';

export const HomePageWrapper = styled.div`
  .list-btn {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 24px 0;
    gap: 24px;
  }

  @media screen and (max-width: 350px) {
    .list-btn {
      flex-direction: column;
    }
  }
`;

export const ClientWrapper = styled.div`
  background-position: center;
  background-repeat: no-repeat;
`;

export const ExploreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
  padding-bottom: 100px;

  .group-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 59px;
    color: #373737;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 40px 0;
    width: 100%;

    &-left {
      width: 100%;
      height: 200px;
      background-size: cover;
    }

    &-right {
      width: 100%;
    }
  }

  .item {
    display: flex;
    gap: 24px;
    align-items: center;
    margin: 12px 0;

    &-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #1f1f1f;
      /* margin-bottom: 10px; */
    }

    &-content {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #595959;
    }
  }

  //Ipad

  @media screen and (min-width: 768px) {
    padding-bottom: 150px;

    .group-title {
      font-size: 36px;
      line-height: 59px;
    }

    .content {
      flex-direction: row;
      &-left {
        width: 690px;
        height: 380px;
      }

      &-right {
        width: 500px;
      }
    }
  }
`;

export const ClientMindWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;

  .mg-10 {
    background: #ffffff;
    padding: 12px;
    border-radius: 10px;
  }

  .box-container {
    width: 300px;
    padding: 12px;
    height: 100%;
    /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1); */

    &-comment {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7b7b7b;
      margin: 14px 0;
    }
  }

  .slick-slider {
    max-width: 1200px;
    display: flex;
    align-items: center;
  }

  .client-talk {
    font-weight: 600;
    font-size: 18px;

    color: black;
  }

  .user {
    gap: 20px;

    &-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: #ff5300;
    }

    &-position {
      font-weight: 300;
      font-size: 12.8px;
      line-height: 19px;
      color: #7b7b7b;
    }
  }
  //Ipad

  @media screen and (min-width: 768px) {
    padding: 40px 0;
    .client-talk {
      padding: 15px 0 25px;
      font-size: 36px;
      line-height: 59px;
    }
  }
`;

export const HeaderWrapper = styled.div`
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;

  .tac {
    color: #fff;
    text-align: center;

    &-1 {
      font-size: 24px;
      line-height: 36px;
      font-weight: 700;
      margin: 24px 0;
    }

    &-2 {
      line-height: 21px;
      font-size: 14px;
      font-weight: 400;
    }
  }

  //Ipad

  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;

    .tac {
      color: #fff;
      text-align: center;

      &-1 {
        font-size: 36px;
        line-height: 54px;
      }

      &-2 {
        line-height: 24px;
        font-size: 16px;
      }
    }
  }
`;
export const DivCustom = styled.div`
  /* padding: 60px 0; */
  /* background-color: #f2f8fb; */

  .todo-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 59px;
    color: #373737;
    margin: 8px 0 25px;
  }

  @media screen and (min-width: 768px) {
    .todo-title {
      font-size: 36px;
      line-height: 59px;
    }
  }
`;

export const ToDoListWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .todo-item {
    padding: 24px;
    background: #fff;
    flex-direction: column;
    box-shadow: 0px 1px 20px rgba(0, 36, 125, 0.06);
    border-radius: 10px;
    transition: 1s;
    :hover {
      cursor: pointer;
      background: linear-gradient(90deg, #ff5400 0%, #ff8a00 100%);

      img {
        transform: scale(1.2);
        transition: 0.5s;
      }
    }
  }

  .item-title {
    margin: 12px 0 8px;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 38px; */
    text-align: center;
  }

  .item-content {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
  }

  @media screen and (min-width: 768px) {
    .item-title {
      font-size: 20px;
      line-height: 30px;
    }

    .item-content {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const OutPartnersWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;

  .todo-item {
    margin: 12px;
    padding: 38px 24px;
    background: #fff;
    flex-direction: column;
    box-shadow: 0px 1px 20px rgba(0, 36, 125, 0.06);
    border-radius: 10px;

    :hover {
      cursor: pointer;
      background: linear-gradient(90deg, #ff5400 0%, #ff8a00 100%),
        linear-gradient(90deg, #0085d6 0%, #006fd6 100%), #0095ef;
    }
  }

  .item-title {
    margin: 12px 0 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
  }

  .item-content {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .todo-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 59px;
    color: #373737;
    margin: 8px 0 25px;
  }

  @media screen and (min-width: 768px) {
    .todo-title {
      font-size: 36px;
      line-height: 59px;
    }
  }
`;

export const ExperienceWrapper = styled.div`
  height: 700px;
  background-position: center cover;
  background-repeat: no-repeat;
  background-size: cover;

  .experience-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 59px;
    color: #fff;
  }

  //Ipad
  @media screen and (min-width: 768px) {
    height: 880px;

    .experience-title {
      font-size: 36px;
      line-height: 59px;
    }
  }
`;

export const CapabilitiWrapper = styled.div`
  margin-bottom: 60px;

  .capability {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ff5300;
    margin-top: 20px;

    &-title {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #373737;
    }
  }

  .capability::after {
    content: '';
    width: 40px;
    display: block;
    border-bottom: 1px solid #ff5300;
    position: relative;
    top: -9px;
    right: -132px;
  }

  //Ipad

  @media screen and (min-width: 768px) {
    padding: 60px 0 0;
    .capability {
      margin-top: 0;

      &-title {
        font-size: 36px;
        line-height: 59px;
      }
    }
  }
`;

export const PortfolioWrapper = styled.div`
  .col-wrapper {
    position: relative;
  }

  .titlehover {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(
      90deg,
      rgba(255, 84, 0, 0.9) 0%,
      rgba(255, 138, 0, 0.9) 100%
    );
    border-radius: 10px;
    padding: 10px;
    color: white;
    width: 100%;
    height: 100%;
  }

  .col-wrapper:hover .titlehover {
    display: block;
    padding: 20%;
  }

  .row-project {
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menu {
    color: #262626;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .pro-header {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #ff5300;
    text-align: center;
    margin: 0 auto;
  }

  .pro-header::after {
    content: '';
    width: 40px;
    display: block;
    border-bottom: 1px solid #ff5300;
    position: relative;
    top: -14px;
    left: 54%;
  }
  .our-project {
    font-weight: 600;
    font-size: 24px;
    line-height: 59px;
    color: #373737;
    margin-bottom: 18px;
  }

  .menu-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #373737;
    margin: 0 12px;
  }

  .temp-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  //Ipad

  @media screen and (min-width: 768px) {
    .our-project {
      font-size: 36px;
      line-height: 59px;
    }
    .menu-item {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const ExperienceBox = styled.div`
  margin-top: 30px;

  .img-card {
    border-radius: 10px;
  }

  .swiper-btn {
    background: linear-gradient(90deg, #ff5400 0%, #ff8a00 100%), #ff5300;
    object-fit: cover;
    width: 100%;
    position: absolute;
    bottom: 0px;
    border-radius: 0px 0px 10px 10px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20.5926px;
    line-height: 31px;
    color: #ffffff;
  }

  .swiper_container {
    height: 40rem;
    padding: 2rem 0;
    position: relative;
  }

  .swiper-slide {
    width: 15rem;
    height: 25rem;
    position: relative;
  }

  .swiper-slide img {
    width: 15rem;
    height: 25rem;
    border-radius: 10px;
    object-fit: cover;
  }

  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display: none;
  }

  .rightClass {
    transform: translate3d(0px, 0px, -180px) rotateX(0deg) rotateY(-26deg)
      scale(1);
  }

  /* .slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

  .slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
  }

  .slider-controler .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }

  .slider-controler .slider-arrow ion-icon {
    font-size: 2rem;
    color: #222224;
  }

  .slider-controler .slider-arrow::after {
    content: '';
  }

  .swiper-pagination {
    position: relative;
    width: 15rem !important;
    bottom: 1rem;
  }

  .swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }

  .swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
  }

  //Ipad
  @media screen and (min-width: 768px) {
    .swiper-slide {
      width: 320px;
      height: 400px;
      position: relative;
    }

    .swiper-slide img {
      width: 320px;
      height: 400px;
      border-radius: 10px;
      object-fit: cover;
    }
  }
`;
