import React from 'react';
import { PopularNewsBox } from '../styled';
import { Col, Row } from 'antd';
import { popularData } from '../data';
import { useNavigate } from 'react-router-dom';

const PopularNews = () => {
  const navigate = useNavigate();
  return (
    <PopularNewsBox>
      <div className='popular-header'>Popular News</div>
      {popularData.map((item, idx) => {
        return (
          <Row
            gutter={11}
            className='row-popular'
            key={`popular-${idx + 1}`}
            onClick={() => {
              navigate(`/explore-news/${idx + 1}`);
            }}
          >
            <Col span={8}>
              <img alt='' src={item.image} width={'100%'} />
            </Col>
            <Col span={16}>
              <div className='text-1'>{item.title}</div>
              <div className='text-2'>{item.time}</div>
            </Col>
          </Row>
        );
      })}
    </PopularNewsBox>
  );
};

export default PopularNews;
