import React from 'react';

const Infomation = () => {
  return (
    <div>
      <img
        alt=''
        src={process.env.PUBLIC_URL + '/images/detail.png'}
        width={'100%'}
      />
    </div>
  );
};

export default Infomation;
