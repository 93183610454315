import React from 'react';

export const ICON_ADDRESS = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M12.8125 4.99953V18.1245M7.1875 1.87453V14.9995M12.2371 4.86828L7.76289 2.00578C7.58492 1.91687 7.38829 1.87173 7.18936 1.87411C6.99043 1.87649 6.79494 1.92632 6.61914 2.01945L2.20742 4.9796C2.10705 5.03278 2.02307 5.11229 1.9645 5.20961C1.90593 5.30693 1.87499 5.41837 1.875 5.53195V17.0866C1.87499 17.1944 1.90286 17.3004 1.95589 17.3942C2.00892 17.488 2.08532 17.5666 2.17767 17.6222C2.27001 17.6778 2.37517 17.7085 2.48291 17.7115C2.59066 17.7144 2.69733 17.6894 2.79258 17.639L6.61367 15.1472C6.79091 15.0533 6.98813 15.0034 7.1887 15.0018C7.38926 15.0002 7.58728 15.0468 7.76602 15.1378L12.1355 17.9894C12.3127 18.0797 12.5089 18.1264 12.7077 18.1256C12.9066 18.1249 13.1024 18.0767 13.2789 17.9851L17.7879 15.0187C17.8895 14.9659 17.9747 14.8863 18.0341 14.7885C18.0935 14.6907 18.125 14.5784 18.125 14.464V2.91242C18.125 2.80463 18.0971 2.69868 18.0441 2.60484C17.9911 2.51101 17.9147 2.43248 17.8223 2.37689C17.73 2.3213 17.6248 2.29054 17.5171 2.2876C17.4093 2.28465 17.3027 2.30962 17.2074 2.36007L13.3793 4.8546C13.2037 4.94748 13.0085 4.99716 12.8099 4.99954C12.6112 5.00192 12.4149 4.95692 12.2371 4.86828Z'
        fill='currentColor'
        stroke='#FF5400'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const ICON_PHONE = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M11.7084 1.66707C13.407 1.84605 14.9936 2.5994 16.2058 3.80256C17.4181 5.00571 18.1834 6.58658 18.3751 8.28374M11.7084 5.00041C12.528 5.16202 13.2801 5.56626 13.8671 6.16066C14.4541 6.75507 14.8488 7.51218 15.0001 8.33374M18.3334 14.1004V16.6004C18.3344 16.8325 18.2868 17.0622 18.1939 17.2749C18.1009 17.4875 17.9645 17.6784 17.7935 17.8353C17.6225 17.9922 17.4206 18.1116 17.2007 18.186C16.9809 18.2603 16.7479 18.288 16.5168 18.2671C13.9525 17.9884 11.4893 17.1122 9.32511 15.7087C7.31163 14.4293 5.60455 12.7222 4.32511 10.7087C2.91676 8.53474 2.04031 6.05957 1.76677 3.48374C1.74595 3.2533 1.77334 3.02104 1.84719 2.80176C1.92105 2.58248 2.03975 2.38098 2.19575 2.21009C2.35174 2.0392 2.54161 1.90266 2.75327 1.80917C2.96492 1.71569 3.19372 1.66729 3.42511 1.66707H5.92511C6.32953 1.66309 6.7216 1.80631 7.02824 2.07002C7.33488 2.33373 7.53517 2.69995 7.59177 3.10041C7.69729 3.90046 7.89298 4.68601 8.17511 5.44207C8.28723 5.74034 8.31149 6.0645 8.24503 6.37614C8.17857 6.68778 8.02416 6.97383 7.80011 7.20041L6.74177 8.25874C7.92807 10.345 9.65549 12.0724 11.7418 13.2587L12.8001 12.2004C13.0267 11.9764 13.3127 11.8219 13.6244 11.7555C13.936 11.689 14.2602 11.7133 14.5584 11.8254C15.3145 12.1075 16.1001 12.3032 16.9001 12.4087C17.3049 12.4658 17.6746 12.6697 17.9389 12.9817C18.2032 13.2936 18.3436 13.6917 18.3334 14.1004Z'
        stroke='#FF5400'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const ICON_MAIL = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M16.6667 0.333008C17.0871 0.332875 17.4921 0.49168 17.8005 0.777589C18.1088 1.0635 18.2976 1.45538 18.3292 1.87467L18.3333 1.99967V11.9997C18.3335 12.4202 18.1747 12.8251 17.8888 13.1335C17.6028 13.4418 17.211 13.6306 16.7917 13.6622L16.6667 13.6663H3.33333C2.91285 13.6665 2.50786 13.5077 2.19954 13.2218C1.89123 12.9359 1.70237 12.544 1.67083 12.1247L1.66667 11.9997V11.1663H3.33333V11.9997H16.6667V3.17801L10.8833 8.96134C10.6646 9.18006 10.3725 9.30989 10.0636 9.32575C9.75466 9.3416 9.45082 9.24235 9.21083 9.04717L9.11583 8.96134L3.33333 3.17801V3.66634H1.66667V1.99967C1.66653 1.57919 1.82534 1.1742 2.11125 0.865885C2.39716 0.557567 2.78904 0.368711 3.20833 0.337175L3.33333 0.333008H16.6667ZM5 7.83301C5.22101 7.83301 5.43298 7.9208 5.58926 8.07709C5.74554 8.23337 5.83333 8.44533 5.83333 8.66634C5.83333 8.88735 5.74554 9.09932 5.58926 9.2556C5.43298 9.41188 5.22101 9.49967 5 9.49967H0.833333C0.61232 9.49967 0.400358 9.41188 0.244078 9.2556C0.0877974 9.09932 0 8.88735 0 8.66634C0 8.44533 0.0877974 8.23337 0.244078 8.07709C0.400358 7.9208 0.61232 7.83301 0.833333 7.83301H5ZM15.4883 1.99967H4.51167L10 7.48801L15.4883 1.99967ZM4.16667 5.33301C4.37907 5.33324 4.58336 5.41457 4.73781 5.56038C4.89225 5.70619 4.9852 5.90547 4.99764 6.1175C5.01009 6.32954 4.9411 6.53832 4.80478 6.7012C4.66845 6.86408 4.47508 6.96875 4.26417 6.99384L4.16667 6.99967H1.66667C1.45427 6.99944 1.24997 6.91811 1.09553 6.7723C0.94108 6.62649 0.848138 6.42721 0.83569 6.21518C0.823242 6.00314 0.892229 5.79436 1.02855 5.63148C1.16488 5.4686 1.35825 5.36393 1.56917 5.33884L1.66667 5.33301H4.16667Z'
        fill='#FF5400'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
