import React from 'react';
import Category from '~/pages/Explore/components/Category';
import Contact from '~/pages/ServiceSystem/components/Contact';

const ContactUs = () => {
  return (
    <div>
      <Category />
      <div className='hidden md:block'>
        <Contact />
      </div>
    </div>
  );
};

export default ContactUs;
