import { Button } from 'antd';
import styled from 'styled-components';

export const ButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => (color ? color : '#FFFFFF')};
  border-radius: 8px;
  font-size: ${({ size }) => (size ? size : 16)}px !important;
  height: 48px;
  min-width: 160px;
  border: none;
  ${({ backgroundColor }) =>
    `background: ${
      backgroundColor
        ? backgroundColor
        : 'linear-gradient(90deg, #FF5400 0%, #FF8A00 100%)'
    }`};

  :hover {
    path {
      fill: #ffffff !important;
    }
  }

  .btn-name {
    height: 24px;
    line-height: 24px;
  }
`;
