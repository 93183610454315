import React from 'react';
import SvgIcon from '~/components/SvgIcon';
import { ContactBox } from '../styled';

const Contact = () => {
  return (
    <ContactBox
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/servicesImg/contact.png)`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <SvgIcon name='orange-phone' />
      <div>Have Any Question?</div>
      <div>Call Us Today!</div>
      <div>+84123456789</div>
    </ContactBox>
  );
};

export default Contact;
