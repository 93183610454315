import React from 'react';
import { NewsDetailWrapper } from './styled';
import { LayoutCommon } from '~/styles/genaralStyled';
import { Col, Row } from 'antd';
import PopularNews from './components/PopularNews';
import NewsDetailItem from './components/NewsItem';

const NewsDetail = () => {
  return (
    <NewsDetailWrapper>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/aboutUs/bg-about-us.png)`
        }}
        className='bg-top'
      >
        <LayoutCommon>
          <div className='header header-1'>NEWS</div>
          <div className='header header-2'>
            Teva And Coco & Breezy Collaborate To Create A Chic Outdoor
            Collection
          </div>
          <div className='header header-3'>
            THE COUPLE WILL BE RECOGNIZED FOR THEIR HUMANITARIAN EFFORTS WHEN
            THIS YEAR’S CEREMONY PREMIERES SATURDAY, FEBRUARY 25 AT 8:00 PM
            ET/PT ON BET.
          </div>
        </LayoutCommon>
      </div>
      <LayoutCommon>
        <Row gutter={24} style={{ paddingTop: '40px' }}>
          <Col xl={18} md={18} sm={24} xs={24}>
            <NewsDetailItem />
          </Col>
          <Col xl={6} md={6} sm={24} xs={24}>
            <PopularNews />
          </Col>
        </Row>
      </LayoutCommon>
    </NewsDetailWrapper>
  );
};

export default NewsDetail;
