import {
  ICON_ANDROID_STUDIO,
  ICON_AWS,
  ICON_AZURE,
  ICON_CENTOS,
  ICON_DART,
  ICON_DJANGO,
  ICON_GOOGLE_CLOUD,
  ICON_GO_LANG,
  ICON_JAVA,
  ICON_JS,
  ICON_LARAVEL,
  ICON_MICROSOFT_TEAMS,
  ICON_MONGO_DB,
  ICON_MYSQL,
  ICON_NEST_JS,
  ICON_NODE_JS,
  ICON_NOTEPAD,
  ICON_ORACLE,
  ICON_PHP,
  ICON_POSTGRESQL,
  ICON_PYTHON,
  ICON_REACT,
  ICON_REACT_NATIVE,
  ICON_RUST,
  ICON_SKYPE,
  ICON_SLACK,
  ICON_TELEGRAM,
  ICON_UBUNTU,
  ICON_VSCODE,
  ICON_VUE_JS,
  ICON_WEBSTORM,
  ICON_WINDOWN,
  ICON_ZOOM,
  ICON_SWIFT,
  ICON_KOTLIN,
  ICON_C,
  ICON_RUBY,
  ICON_TYPESCRIPT
} from '~/assets';

export const dataTechStackBox = [
  {
    title: 'Programming languages',
    childrend: [
      {
        img: ICON_RUST,
        category: 'Rust'
      },
      {
        img: ICON_PYTHON,
        category: 'Python'
      },
      {
        img: ICON_PHP,
        category: 'PHP'
      },
      {
        img: ICON_JS,
        category: 'Javascript'
      },
      {
        img: ICON_DART,
        category: 'Dart'
      },
      {
        img: ICON_JAVA,
        category: 'Java'
      },
      {
        img: ICON_GO_LANG,
        category: 'Go'
      },
      {
        img: ICON_SWIFT,
        category: 'Swift'
      },
      {
        img: ICON_KOTLIN,
        category: 'Kotlin'
      },
      {
        img: ICON_C,
        category: 'C#'
      },
      {
        img: ICON_RUBY,
        category: 'Ruby'
      },
      {
        img: ICON_TYPESCRIPT,
        category: 'Typescript'
      }
    ]
  },
  {
    title: 'Frameworks',
    childrend: [
      {
        img: ICON_REACT_NATIVE,
        category: 'React native'
      },
      {
        img: ICON_DJANGO,
        category: 'Django'
      },
      {
        img: ICON_NODE_JS,
        category: 'Node.js'
      },
      {
        img: ICON_NEST_JS,
        category: 'NestJS'
      },
      {
        img: ICON_VUE_JS,
        category: 'Vue.js'
      },
      {
        img: ICON_REACT,
        category: 'React'
      },
      {
        img: ICON_LARAVEL,
        category: 'Laravel'
      }
    ]
  },
  {
    title: 'Database',
    childrend: [
      {
        img: ICON_POSTGRESQL,
        category: 'PostgreSQL'
      },
      {
        img: ICON_MYSQL,
        category: 'MySQL'
      },
      {
        img: ICON_MONGO_DB,
        category: 'Mongo DB'
      }
    ]
  },
  {
    title: 'Development tools',
    childrend: [
      {
        img: ICON_WEBSTORM,
        category: 'Webstorm'
      },
      {
        img: ICON_VSCODE,
        category: 'Visual Studio Code'
      },
      {
        img: ICON_NOTEPAD,
        category: 'Notepad++'
      },
      {
        img: ICON_ANDROID_STUDIO,
        category: 'Android Studio '
      }
    ]
  },
  {
    title: 'Server OS',
    childrend: [
      {
        img: ICON_UBUNTU,
        category: 'Ubuntu'
      },
      {
        img: ICON_WINDOWN,
        category: 'Window Server'
      },
      {
        img: ICON_CENTOS,
        category: 'CentOS'
      }
    ]
  },
  {
    title: 'Cloud',
    childrend: [
      {
        img: ICON_AWS,
        category: 'AWS'
      },
      {
        img: ICON_AZURE,
        category: 'Azure'
      },
      {
        img: ICON_GOOGLE_CLOUD,
        category: 'Google Cloud'
      },
      {
        img: ICON_ORACLE,
        category: 'Oracle '
      }
    ]
  },
  {
    title: 'Communication tools',
    childrend: [
      {
        img: ICON_ZOOM,
        category: 'Zoom'
      },
      {
        img: ICON_TELEGRAM,
        category: 'Telegram'
      },
      {
        img: ICON_SLACK,
        category: 'Slack'
      },
      {
        img: ICON_SKYPE,
        category: 'Skype'
      },
      {
        img: ICON_MICROSOFT_TEAMS,
        category: 'Google Team'
      }
    ]
  }
];
