import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from '~/common/hooks/useTranslation';
import { LayoutCommon } from '../../styles/genaralStyled';
import { ExpertiseWrapper, SubExpertiseWrapper } from './styled';
// import Portfolio from '../HomePage/components/Portfolio';
import TechStackBox from './components/TechStackBox';
import TechnologyBox from './components/TechnologyBox';

const Expertise = () => {
  const { t } = useTranslation();
  return (
    <ExpertiseWrapper>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/expertises/bg-expertises.png)`
        }}
        className='bg-expertises'
      >
        <LayoutCommon>
          <div className='main-title md:pt-[120px]'>
            {t('expertises.title')}
          </div>
        </LayoutCommon>
      </div>

      <LayoutCommon>
        <SubExpertiseWrapper>
          <Row gutter={28} className='flex-flow'>
            <Col xl={12} md={12} sm={12} xs={24}>
              <div className='capability'>{t('expertises.title')}</div>
              <div className='capability-title'>{t('expertises.subTitle')}</div>
              <div className='capability-main'>{t('expertises.des')}</div>
            </Col>
            <Col xl={12} md={12} sm={12} xs={24}>
              <img
                className='max-w-[550px] mt-[-90px]'
                src={'./images/expertises/banner.png'}
                alt=''
                width={'100%'}
              />
            </Col>
          </Row>
        </SubExpertiseWrapper>
      </LayoutCommon>

      <TechnologyBox />

      {/* <ExperienceWrapper
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/listService/bg-service.png)`
        }}
      >
        <LayoutCommon>
          <div className='header-content'>
            <div className='about-info-type-1'>
              {t('homePage.weService.title')}
            </div>
            <div className='experience-title'>
              {t('homePage.weService.des')}
            </div>
          </div>
          <Experience />
        </LayoutCommon>
      </ExperienceWrapper> */}

      {/* <DevelopmentFields /> */}
      <TechStackBox />
      {/* <Portfolio t={t} /> */}

      {/* <Portfolio t={t} /> */}
    </ExpertiseWrapper>
  );
};

export default Expertise;
