import React from 'react';
import ReactDOM from 'react-dom/client';
import '@splidejs/react-splide/css';
import './styles/global.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import { client } from './Apollo/apollo-client';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ConfigProvider
    theme={{
      hashed: false,
      components: {
        Modal: {
          borderRadius: 0
        }
      }
    }}
  >
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
