import React, { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import AppLayout from '../layouts/AppLayout/AppLayout';
import { ROUTE_PATH } from './route.constant';
import PageNotFound from '../pages/PageNotFound';
import HomePage from '~/pages/HomePage';
import AboutUs from '~/pages/AboutUs';
import ServiceSystem from '~/pages/ServiceSystem';
import Explore from '~/pages/Explore';
import CaseStudy from '~/pages/CaseStudy';
import NewsDetail from '~/pages/NewsDetail';
import OnDemandSoftwareDev from '~/pages/ServiceSystem/OnDemandSoftwareDev';
import RemoteSoftware from '~/pages/ServiceSystem/RemoteSoftware';
import GameDevelopment from '~/pages/ServiceSystem/GameDevelopment';
import Career from '~/pages/Career';
import JobDetail from '~/pages/JobDetail';
import ContactUs from '~/pages/ContactUs';
import AppLocale from '~/common/langs';
import { useTheme } from '~/common/theme/redux/hooks/useTheme';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import Expertise from '~/pages/Expertise';
import RDSolutions from '~/pages/ServiceSystem/RDSolutions';
import DetailPortfolio from '~/pages/CaseStudy/Portfolio/detailPortfolio';
import RouteMetadata from './route.metadata';

import Anonymous from '~/layouts/Anonymous';
import AdminSystem from '~/layouts/AdminSystem';
import AuthLayout from '~/layouts/AdminSystem/AuthLayout';
import MainLayout from '~/layouts/AdminSystem/MainLayout';
import TechBlogs from '~/pages/TechBlogs';
import BlogDetail from '~/pages/BlogDetail';

const Authentication = React.lazy(() =>
  import('../pages/ADMIN/Authentication')
);

const BlogManagement = React.lazy(() => import('../pages/ADMIN/Blog'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <PageNotFound />,
    children: [
      {
        element: <Anonymous />,
        children: [
          {
            path: '/',
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <HomePage />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.ABOUT_US,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <AboutUs />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.CONTACT_US,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <ContactUs />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.SERVICES,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <ServiceSystem />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.EXPLORE_NEWS,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <Explore />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.PORTFOLIO,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <CaseStudy />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.CAREER,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <Career />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.JOB_DETAIL,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <JobDetail />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.NEW_DETAIL,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <NewsDetail />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.EXPERTISE,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <Expertise />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.ON_DEMAND_SOFTWARE_DEVELOPMENT,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <OnDemandSoftwareDev />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.RD_SOLUTION,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <RDSolutions />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.REMOTE_SOFTWARE,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <RemoteSoftware />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.GAME_DEVELOPMENT,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <GameDevelopment />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.DETAIL_PORTFOLIO,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <DetailPortfolio />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.TECH_BLOGS,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <TechBlogs />
              </Suspense>
            )
          },
          {
            path: ROUTE_PATH.TECH_BLOGS_DETAIL,
            element: (
              <Suspense fallback={null}>
                <RouteMetadata />
                <BlogDetail />
              </Suspense>
            )
          }
        ]
      },
      {
        element: <AdminSystem />,
        children: [
          {
            element: <AuthLayout />,
            children: [
              {
                path: ROUTE_PATH.LOGIN,
                element: <Authentication />
              }
            ]
          },
          {
            element: <MainLayout />,
            children: [
              {
                path: ROUTE_PATH.BLOG_MANAGEMENT,
                element: <BlogManagement />
              }
            ]
          }
        ]
      }
    ]
  }
]);

export const Routes = () => {
  const {
    data: { locale, direction }
  } = useTheme();

  const currentAppLocale = AppLocale[locale];

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider
        locale={currentAppLocale.antd}
        direction={direction}
        autoInsertSpaceInButton={false}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </IntlProvider>
  );
};
