import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '~/routes/route.constant';

const PageNotFound = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const handleNavigateToAdmin = () => {
    if (pathname.indexOf('/admin') !== -1) navigate(ROUTE_PATH.LOGIN);
    else navigate('/');
  };

  useEffect(() => {
    handleNavigateToAdmin();
  }, []);

  return (
    <div className='h-screen w-full flex items-center justify-center'>
      <Spin spinning={true} />
    </div>
  );
};

export default PageNotFound;
