import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { LayoutCommon } from '~/styles/genaralStyled';
import Footer from './Footer';
import { AnonymousWrapper } from './styled';
import { scrollToTop } from '~/helpers/utils';
import MenuTop from './MenuTop/MenuTop';

const Anonymous = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return (
    <AnonymousWrapper className={`light`}>
      <MenuTop />
      <Outlet />
      <div
        className='sticky-footer'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg-footer.jpg)`
        }}
      >
        <div className='footer'>
          <LayoutCommon>
            <Footer />
          </LayoutCommon>
        </div>
        <div className='bt-footer'>
          &copy; 2023 1BITLAB Technology JSC,. All Rights Reserved
        </div>
      </div>
    </AnonymousWrapper>
  );
};

export default Anonymous;
