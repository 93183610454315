export const services = [
  {
    category: 'Web Development',
    technical: [
      'UI/UX design',
      'Full stack development',
      'Frontend',
      'Backend',
      'DevOps',
      'Third-party integration',
      'Quality assurance',
      'Maintenance'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/rafiki.png'
  },
  {
    category: 'Mobile App Development',
    technical: [
      'UI/UX design',
      'Full stack development',
      'Third-party integration',
      'Backend',
      'Native (iOS/Android)/ Hybrid mobile development',
      'Quality assurance',
      'Maintenance & support'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/rafiki_mobile.png'
  },
  {
    category: 'Blockchain Development',
    technical: [
      'Consulting',
      'Smart Contract Development',
      'dApp development',
      'Tokenization',
      'Public blockchain development: BTC, ETH, Solana, Polygon, BNB, Oasys, Base ...'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/blockchain.png'
  },
  {
    category: 'AI Development',
    technical: [
      'Consulting',
      'Development',
      'Implementation',
      'Deep learning/ Machine learning/Computer vision'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/AI.png'
  },
  {
    category: 'VR/AR Development ',
    technical: [
      'Augmented reality app development',
      '3D design & modeling',
      'Virtual reality software development',
      'AR/VR Consulting'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/VR.png'
  }
];

export const advantages = [
  {
    title: 'Cost Reduction',
    des: [
      'Competitive prices, with the potential to save up to 30-40% on in-house costs due to optimizing resources, tools, and know-hows.'
    ]
  },
  {
    title: 'Swift Team Assembly',
    des: [
      'Rapidly form a diverse 10-member team with assorted skills within 2-4 weeks.'
    ]
  },
  {
    title: 'Excellent Post-purchase Support',
    des: ['Warranty included for 6 months to 1 year.']
  },
  {
    title: 'Scalability',
    des: [
      'Offshore partners manage development teams, allowing flexible resource allocation based on evolving demands and requirements.'
    ]
  },
  {
    title: 'Skilled Workforce',
    des: [
      'Offshore software development countries produce numerous well-educated software developers annually.',
      `The team's expertise spans various startup domains, offering both technical skills and business advisory support.`
    ]
  },
  {
    title: 'Time Efficiency',
    des: [
      'Offshore companies excel in Agile methodologies and diverse software tools.',
      'Ensuring timely project completion and adaptability to changes, fostering continuous improvement.'
    ]
  }
];
