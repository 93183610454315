/* eslint-disable no-useless-escape */
export const REGEX = {
  PASSWORD: /^[a-zA-Z0-9]{6,14}$/,
  NOT_WHITESPACE: /^(?!.*[\s])/,
  SPECIAL_CHARACTERS: /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/,
  NOT_WHITESPACE_START: /^[^\s].*/,
  INT_NUMBER: /^[0-9]+$/,
  PHONE_NUMBER: /^[0-9 -]+$/
};

export const isValidEmail = () => {
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return regex;
};

export const isValidPhoneNumber = () => {
  const regex = /^(?=.*\d).{10,11}$/;
  return regex;
};
