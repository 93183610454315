import React from 'react';
import { TechnologyBoxWrapper } from '../../styled';
import { useTranslation } from '~/common/hooks/useTranslation';
import { LayoutCommon } from '~/styles/genaralStyled';
import BallCanvas from '~/components/shared-components/BallCanvas';
import { dataTechnologyBox } from './datas';

const TechnologyBox = () => {
  const { t } = useTranslation();
  return (
    <TechnologyBoxWrapper>
      <LayoutCommon>
        <div className='flex flex-wrap gap-4 m-auto justify-center'>
          {dataTechnologyBox?.map((data, index) => (
            <div
              className='tech__item bg-[#F2F8FB] flex items-center flex-col p-5 text-center justify-self-center w-full h-full sm:w-[282px] sm:h-[282px] ease-in-out duration-300'
              key={index?.toString()}
              style={{ borderRadius: 10 }}
            >
              <div className='flex flex-col mb-2 h-[150px] items-center justify-center '>
                <div className='w-[100px] h-[100px] cursor-pointer tech__item_ball ease-in-out duration-300'>
                  <BallCanvas icon={data?.img} />
                </div>
                <div className='text-[16px] font-bold'>
                  {t(`expertises.technology.title.${data.title}`)}
                </div>
              </div>

              <div className='text-[#7B7B7B] text-[14px]'>
                {t(`expertises.technology.des.${data.des}`)}
              </div>
            </div>
          ))}
        </div>
      </LayoutCommon>
    </TechnologyBoxWrapper>
  );
};

export default TechnologyBox;
