import {
  CHANGE_LOCALE,
  SWITCH_THEME,
  CHANGE_SERVICE,
  CHANGE_DETAIL
} from '~/common/constants/ThemeConstant';

export function onLocaleChange(locale) {
  return {
    type: CHANGE_LOCALE,
    locale
  };
}

export function onSwitchTheme(currentTheme) {
  return {
    type: SWITCH_THEME,
    currentTheme
  };
}

export function onChangeService(service) {
  return {
    type: CHANGE_SERVICE,
    service
  };
}

export function onChangeDetail(portfolio) {
  return {
    type: CHANGE_DETAIL,
    portfolio
  };
}

export default {
  onLocaleChange,
  onSwitchTheme,
  onChangeService,
  onChangeDetail
};
