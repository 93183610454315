import styled from 'styled-components';

export const WebAppWrapper = styled.div`
  .bg-service {
    height: 400px;
    padding-top: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
  }

  .service-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
  }
`;

export const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 310px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(
      180deg,
      rgba(0, 149, 239, 0.6) 0%,
      rgba(0, 30, 48, 0.6) 100%
    ),
    url(.jpg), #d9d9d9;
  border-radius: 10px;
  padding: 27px 36px;
  margin-top: 16px;
  margin-bottom: 120px;

  div {
    font-weight: 700;
    line-height: 48px;
    color: #ffffff;
    text-transform: capitalize;
    :last-child {
      font-size: 24px;
    }

    :not(last-child) {
      font-size: 20px;
    }
  }

  @media screen and (min-width: 768px) {
    .call-us {
      margin-bottom: 0;
    }
  }
`;

export const BusinessWrapper = styled.div`
  .text-1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: justify;
    color: #000000;
    padding: 24px 0 0;
  }

  .text-2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 24px 0;
    text-align: justify;
    color: #1f1f1f;
  }

  .text-3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    color: #000000;
    padding: 24px;
  }

  .orange-border {
    border-left: 4px solid #ff5400;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }

  .li-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    color: #000000;
    padding: 6px 0;
  }

  .text-4 {
    font-weight: 700;
    font-size: 24px;
    padding-bottom: 24px;
    line-height: 24px;
    text-align: justify;
    color: #000000;
    margin-top: 20px;
  }

  .ant-collapse-header {
    background: #ffffff;
    padding: 18px 24px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 4px 4px 0px 0px;
  }

  .ant-collapse-header-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
    color: #1f1f1f;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 18px 24px;
  }

  .ant-collapse-content-box {
    font-weight: 400;
    background: #ffffff;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    color: #1f1f1f;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 4px 4px;
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      background: linear-gradient(90deg, #ff5400 0%, #ff8a00 100%), #ffffff;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
      border-radius: 4px 4px 0px 0px !important;
    }
  }

  //Ipad

  @media screen and (min-width: 768px) {
    .text-4 {
      margin: 0;
    }
  }
`;

export const ListServicesWrapper = styled.div`
  .options {
    font-weight: 600;
    font-size: 16px;
    line-height: 38px;
    color: #1f1f1f;
    padding: 11px 16px;
    background: #f0f5ff;
    border-radius: 10px;
    margin-bottom: 8px;
    cursor: pointer;
    :hover {
      background: linear-gradient(90deg, #ff5400 0%, #ff8a00 100%);
      color: #fff;
    }
  }
  //Ipad
`;

export const OnDemandSoftWrapper = styled.div`
  .splide__list {
    justify-content: space-between;
  }
`;
