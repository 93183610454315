export const data = [
  {
    image: process.env.PUBLIC_URL + '/images/explore/newDetail/relevant1.png',
    time: '15/09/2022',
    title: 'New Skill With The Height Quality Camera Lens Fashion',
    domain: 'FASHION'
  },
  {
    image: process.env.PUBLIC_URL + '/images/explore/newDetail/relevant2.png',
    time: '15/09/2022',
    title: 'New Skill With The Height Quality Camera Lens Fashion',
    domain: 'NEWS'
  },
  {
    image: process.env.PUBLIC_URL + '/images/explore/newDetail/relevant3.png',
    time: '15/09/2022',

    title: 'New Skill With The Height Quality Camera Lens Fashion',
    domain: 'NEWS'
  }
];

export const popularData = [
  {
    image: process.env.PUBLIC_URL + '/images/explore/popular/popular1.png',
    time: '10/09/2022',
    title: 'Lorem ipsum dolor sit amet consectetur.'
  },
  {
    image: process.env.PUBLIC_URL + '/images/explore/popular/popular2.png',
    time: '10/09/2022',
    title: 'Lorem ipsum dolor sit amet consectetur.'
  },
  {
    image: process.env.PUBLIC_URL + '/images/explore/popular/popular3.png',
    time: '15/09/2022',
    title: 'Lorem ipsum dolor sit amet consectetur.'
  },
  {
    image: process.env.PUBLIC_URL + '/images/explore/popular/popular4.png',
    time: '15/09/2022',
    title: 'Lorem ipsum dolor sit amet consectetur.'
  }
];
