import React, { useState } from 'react';
import { EffectCoverflow, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ExperienceBox } from '../../styled';
import { useTranslation } from '~/common/hooks/useTranslation';

const Experience = () => {
  const [activeSlide, setActiveSlide] = useState(3);
  const { t } = useTranslation();
  const datas = [
    {
      btn: t('common.btn.education')
    },
    {
      btn: t('common.btn.mediaEntertainment')
    },
    {
      btn: t('common.btn.fintech')
    },

    {
      btn: t('common.btn.healthcare')
    },
    {
      btn: t('common.btn.retailsEcommerce')
    },
    {
      btn: t('common.btn.tourism')
    }
  ];
  return (
    <ExperienceBox>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 12,
          stretch: 0,
          depth: 100,
          modifier: 1.8
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
        initialSlide={3}
        onSlideChange={(slide) => {
          setActiveSlide(slide?.activeIndex);
        }}
        setWrapperSize={true}
        slidesPerGroupSkip={3}
        modules={[EffectCoverflow, Autoplay]}
        className='swiper_container'
      >
        {datas?.map((item, idx) => {
          return (
            <SwiperSlide key={idx}>
              <div className='position-relative'>
                <img src={`/images/exper/${idx + 1}.jpg`} alt='' />
                {idx === activeSlide && (
                  <div className='swiper-btn text-center'>{item.btn}</div>
                )}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </ExperienceBox>
  );
};

export default Experience;
