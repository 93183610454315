import React from 'react';
import { NewsItemBox } from '../styled';
import { data } from '../data';
import { Col, Row } from 'antd';
import Button from '~/components/Button';
import { useNavigate } from 'react-router-dom';

const NewsItem = () => {
  const navigate = useNavigate();
  return (
    <NewsItemBox>
      {data.map((item, idx) => {
        return (
          <Row
            onClick={() => {
              navigate(`/explore-news/${idx + 1}`);
            }}
            className='news-container'
            key={`item-${idx}`}
          >
            <Col
              xl={12}
              md={12}
              sm={24}
              xs={24}
              className='flex justify-center md:justify-start'
            >
              <img src={item.image} height={'100%'} alt='' />
            </Col>
            <Col xl={12} md={12} sm={24} xs={24} className='col-box'>
              <div className='flex'>
                <div className='domain flex'>{item.domain}</div>
                <div className='time'>{item.time}</div>
              </div>
              <div className='title'>{item.title}</div>
              <div className='content'>{item.content}</div>
            </Col>
          </Row>
        );
      })}
      <Button
        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      >
        Learn more
      </Button>
    </NewsItemBox>
  );
};

export default NewsItem;
