export const services = [
  {
    category: 'Web Development',
    technical: [
      'UI/UX design',
      'Full stack development',
      'Frontend',
      'Backend',
      'DevOps',
      'Third-party integration',
      'Quality assurance',
      'Maintenance'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/rafiki.png'
  },
  {
    category: 'Mobile App Development',
    technical: [
      'UI/UX design',
      'Full stack development',
      'Third-party integration',
      'Backend',
      'Native (iOS/Android)/ Hybrid mobile development',
      'Quality assurance',
      'Maintenance & support'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/rafiki_mobile.png'
  },
  {
    category: 'Blockchain Development',
    technical: [
      'Consulting',
      'Smart Contract Development',
      'dApp development',
      'Tokenization',
      'Public blockchain development: BTC, ETH, Solana, Polygon, BNB, Oasys, Base ...'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/blockchain.png'
  },
  {
    category: 'AI Development',
    technical: [
      'Consulting',
      'Development',
      'Implementation',
      'Deep learning/ Machine learning/Computer vision'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/AI.png'
  },
  {
    category: 'VR/AR Development ',
    technical: [
      'Augmented reality app development',
      '3D design & modeling',
      'Virtual reality software development',
      'AR/VR Consulting'
    ],
    img: process.env.PUBLIC_URL + '/images/servicesImg/VR.png'
  }
];

export const advantages = [
  {
    title: 'Flexibility in many ways',
    des: [
      'Flexible in providing labors.',
      'Flexible in terms of working hours.',
      'Flexible in allocating resources to accommodate multiple time zones.',
      'Flexible in terms of methodology to follow and 24X7 support.'
    ]
  },
  {
    title: 'On time Delivery',
    des: [
      'Efficiently manage resources to meet all deadlines effectively.',
      'Real-time daily and weekly reporting for instant updates.'
    ]
  },
  {
    title: 'Certified Resources',
    des: [
      `Product Managers all have more than 8+ years of experience.`,
      `80% of developers with more than 5+ years of experience.`
    ]
  },
  {
    title: 'Cost-effective Solution',
    des: [
      `Competitive cost with high-level resource in Vietnam.`,
      `Simplify operations with management tools for effectiveness and ongoing process improvement.`
    ]
  },
  {
    title: 'High speed',
    des: [
      `Quick in understanding customer needs and market trends.`,
      `Rule number 3: Response in 3 minutes, takes 3 days to propose solutions and 3 months to deliver the project.`
    ]
  }
];
