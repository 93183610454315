import styled from 'styled-components';

export const CaseStudyWrapper = styled.div`
  .bg-service {
    height: 150px;
    padding-top: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  @media screen and (min-width: 768px) {
    .bg-service {
      height: 400px;
    }
  }
`;

export const DetailPortfolioWrapper = styled.div`
  .features {
    display: flex;
    align-items: center;
    height: 372px;
    background: linear-gradient(
      180deg,
      #f2f8fb 0%,
      rgba(242, 248, 251, 0) 100%
    );
  }
  .featuresTitle {
    color: #ff5400;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
  }
  .languages {
    display: flex;
    height: 402px;
    padding: 0px 75.913px 0px 76px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  .title {
    color: #ff5400;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 37.5px;
    text-transform: uppercase;
  }
  .title-content {
    color: var(--Neutral-8, #595959);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 37.5px;
    border-radius: 40px;
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: center;
  }
  .content {
    color: var(--Neutral-8, #595959);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 37.5px;
  }
`;
