import { Col, Row } from 'antd';
import React from 'react';
import { LayoutCommon } from '~/styles/genaralStyled';
import { useTranslation } from '~/common/hooks/useTranslation';
import Button from '~/components/Button';
import SvgIcon from '~/components/SvgIcon';
import { advantages, dataCategoriManage } from './dataManageSystem';
import { ROUTE_PATH } from '~/routes/route.constant';
import { useNavigate } from 'react-router-dom';
const ServiceSystem = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div>
      <LayoutCommon>
        <div className='gap-5 flex items-center max-md:flex-col max-md:items-stretch max-md:gap-0'>
          <div className='w-full md:w-1/2'>
            <div className='text-neutral-800 text-[36px] font-bold self-stretch w-full'>
              Managed Services
            </div>
            <div className='text-[#262626] text-base leading-7 self-stretch mt-4 w-full'>
              Elevate your operational efficiency and reliability with our
              managed services. We provide comprehensive support, maintenance,
              and monitoring to ensure your digital ecosystem operates
              seamlessly.
            </div>
            <Button
              className='mt-4'
              onClick={() => {
                navigate(ROUTE_PATH.CONTACT_US);
              }}
            >
              {t('common.btn.contactUs')}
            </Button>
          </div>
          <div className='w-full md:w-1/2'>
            <img
              src='/images/servicesImg/ManagedServices.png'
              width={'100%'}
              alt=''
              className='aspect-[1.39] object-contain object-center w-full overflow-hidden grow max-md:max-w-full max-md:mt-7'
            />
          </div>
        </div>
      </LayoutCommon>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/servicesImg/toolTechnology.png)`
        }}
        className=''
      >
        <LayoutCommon className='!py-[42px]'>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <div className='text-white text-4xl font-semibold leading-[58.68px] my-2'>
                Why Managed Services?
              </div>
            </Col>
            {advantages.map((advantageItem) => {
              return (
                <Col xl={12} md={12} sm={12} xs={24}>
                  <div className='flex gap-3'>
                    <SvgIcon name='icon-fe-check' />
                    <div className='text-white text-base font-semibold leading-6 grow'>
                      {advantageItem.title}
                    </div>
                  </div>
                  <div className='text-zinc-300 text-base leading-6 self-stretch mt-3 max-md:max-w-full'>
                    <ul className='list-disc pl-7'>
                      {advantageItem.des.map((item) => {
                        return <li key={item}>{item}</li>;
                      })}
                    </ul>
                  </div>
                </Col>
              );
            })}
          </Row>
        </LayoutCommon>
      </div>

      <LayoutCommon>
        <div className='text-neutral-800 text-4xl text-center font-semibold leading-[59px] mt-8  py-7'>
          Categories
        </div>

        <div className='gap-5 flex flex-wrap max-md:flex-col max-md:gap-0 justify-center mb-9'>
          {dataCategoriManage?.map((data) => {
            return (
              <div
                className='flex flex-col w-full sm:w-[50%] lg:w-[32.22%] justify-center items-center shadow-[0_1px_20px_0_rgba(0,36,125,0.06)]'
                key={data?.icon}
              >
                <span className='items-stretch shadow-sm bg-white flex grow flex-col w-full px-4 py-5 rounded-lg max-md:mt-6'>
                  <SvgIcon name={data?.icon} style={{ marginTop: '16px' }} />
                  <div className='text-neutral-800 text-base font-bold leading-6 self-center whitespace-nowrap mt-4'>
                    {data?.title}
                  </div>
                  <div className='text-zinc-600 text-base leading-6 mt-4'>
                    {/* {data?.des} */}
                    <ul className='list-disc pl-7'>
                      {data.des.map((item) => {
                        return <li key={item}>{item}</li>;
                      })}
                    </ul>
                  </div>
                </span>
              </div>
            );
          })}
        </div>
      </LayoutCommon>
    </div>
  );
};

export default ServiceSystem;
