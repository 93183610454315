import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Rate, Tooltip } from 'antd';
import React from 'react';
import SvgIcon from '../../../../components/SvgIcon';
import { LayoutCommon } from '../../../../styles/genaralStyled';
import { ClientMindWrapper, ClientWrapper } from '../../styled';
import { data } from './data';

const ClientMind = (props) => {
  const { t } = props;

  return (
    <ClientWrapper
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/user/bg-user.png)`
      }}
    >
      <LayoutCommon>
        <ClientMindWrapper>
          <div className='about-info-type-2'>
            {t('homePage.ourTestimonial.title')}
          </div>
          <div className='client-talk text-center mb-4'>
            {t('homePage.ourTestimonial.des')}
          </div>
          <div className='w-full'>
            <Splide
              aria-label='My Favorite Images'
              options={{
                width: '100%',

                arrows: true,
                pagination: false,
                lazyLoad: true,
                perPage: 3,
                gap: 10,
                breakpoints: {
                  1024: {
                    perPage: 2
                  },
                  600: {
                    perPage: 1
                  }
                }
              }}
            >
              {data.map((item, idx) => {
                return (
                  <SplideSlide key={idx}>
                    <div className='mg-10'>
                      <Rate allowHalf defaultValue={item.rating} />
                      <div className='abc text-[14px] min-h-[220px] font-[400] text-[#7b7b7b] leading-6 my-[14px] line-clamp-8'>
                        {item.comment}
                      </div>
                      <div
                        className='flex'
                        style={{ justifyContent: 'space-between' }}
                      >
                        <div className='flex user'>
                          <div>
                            <Tooltip title={item.name}>
                              <div className='user-name line-clamp-1'>
                                {item.name}
                              </div>
                            </Tooltip>
                            <div className='user-position'>{item.position}</div>
                          </div>
                        </div>
                        <SvgIcon
                          name='double-quotes'
                          className='w-8 xl:w-[60px]'
                        />
                      </div>
                    </div>
                  </SplideSlide>
                );
              })}
            </Splide>
          </div>
        </ClientMindWrapper>
      </LayoutCommon>
    </ClientWrapper>
  );
};

export default ClientMind;
