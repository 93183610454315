import { Layout, theme } from 'antd';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN_KEY } from '~/constants/init.constants';
import { getRefreshToken, parseJwt } from '~/helpers/utils';
import { ROUTE_PATH } from '~/routes/route.constant';
import Sidebar from './Sidebar';
import { ContainerWrapper } from './styled';

function MainLayout() {
  const { Content, Sider } = Layout;
  const navigate = useNavigate();
  const {
    token: { colorBgContainer }
  } = theme.useToken();

  const isLogin = localStorage.getItem(ACCESS_TOKEN_KEY);
  const refreshToken = getRefreshToken();
  const timeExpiredAccessToken = parseJwt(refreshToken)?.exp;

  useEffect(() => {
    if (!isLogin || timeExpiredAccessToken * 1000 < Date.now()) {
      localStorage.clear();
      return navigate(ROUTE_PATH.LOGIN);
    }
  }, []);

  return (
    <ContainerWrapper>
      <div className='h-20 flex items-center border-b border-t-0 border-x-0 border-solid border-[#0000001A]'>
        <img
          className='h-[54px] pl-[40px]'
          src={process.env.PUBLIC_URL + `/images/${'logo1bitlab-sticky.png'}`}
          alt=''
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(ROUTE_PATH.HOME);
          }}
        />
      </div>
      <Layout>
        <Sider
          width={250}
          style={{
            background: colorBgContainer
          }}
        >
          <Sidebar />
        </Sider>
        <Layout>
          <div className='bg-white'>
            <div className='mt-7 mr-7 p-5 border border-solid border-[#D9D9D9] h-[calc(100vh-124px)] overflow-y-auto'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
        </Layout>
      </Layout>
    </ContainerWrapper>
  );
}

export default MainLayout;
