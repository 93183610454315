import { Checkbox, Input } from 'antd';
import React from 'react';
import { CategoryBox } from '../styled';

const Category = () => {
  return (
    <CategoryBox>
      <div className='input-box'>
        <Input placeholder='Search' />
      </div>
      <div className='category'>
        <div className='category-text'>Categories</div>
        <Checkbox.Group className='checkbox'>
          <Checkbox value={1} className='checkbox-item'>
            All
          </Checkbox>
          <Checkbox value={2} className='checkbox-item'>
            Front-end
          </Checkbox>
          <Checkbox value={3} className='checkbox-item'>
            Back-end
          </Checkbox>
        </Checkbox.Group>
      </div>
    </CategoryBox>
  );
};

export default Category;
