import styled from 'styled-components';

export const NewsDetailWrapper = styled.div`
  .bg-top {
    height: 400px;
    padding-top: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .header {
    text-align: center;

    color: #ffffff;
    &-1 {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      padding-top: 120px;
    }

    &-2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      /* identical to box height */

      padding: 16px 0;
    }

    &-3 {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      /* or 175% */

      color: #f0f0f0;
    }
  }
`;

export const NewsDetailItemBox = styled.div`
  padding-bottom: 150px;

  .col-relavant {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
  }

  .relevant-header {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    text-transform: capitalize;

    color: #1f1f1f;
    padding: 31px 0 25px;
  }

  .text {
    padding: 12px;
    &-1 {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #ff5400;
    }

    &-1::after {
      display: inline-block;
      margin: 0 10px;
      content: '';
      width: 6px;
      height: 6px;
      background: #ff5400;
      border-radius: 6px;
      top: 50%;
      transform: translateY(-50%);
    }

    &-2 {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;

      color: #595959;
    }

    &-3 {
      padding: 0 12px 12px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #1f1f1f;
    }
  }
`;

export const PopularNewsBox = styled.div`
  .popular-header {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    /* PrimarY */

    color: #0079bb;
    padding-bottom: 12px;
  }
  .row-popular {
    padding: 12px 0;
    cursor: pointer;
  }

  .text {
    &-1 {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;

      /* Neutral / 11 */

      color: #1f1f1f;
      padding-bottom: 8px;
    }

    &-2 {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      /* identical to box height, or 200% */

      /* Neutral/7 */

      color: #8c8c8c;
    }
  }
`;
