import parse from 'html-react-parser';
import React from 'react';
import { LayoutCommon } from '../../../styles/genaralStyled';
import { OurTeamsWrapper } from '../styled';
// import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Col, Row } from 'antd';

const OurTeams = (props) => {
  const { t } = props;
  const dataPerson = [
    {
      name: 'Chị Quỳnh',
      fullName: 'Nguyen Thu Quynh',
      role: 'CEO',
      des: 'Boasts over 15 years of diverse technology management experience, Mrs. Quỳnh, CEO of 1BITLAB is recognized for her dynamic leadership and innovative thinking. She stands among the few female Vietnamese IT founders/CEOs, having led a top 10 Blockchain company. She has experience in successfully mobilizing many start-up projects, some projects have raised capital exceeding 2 million USD.'
    },
    {
      name: 'Anh Trình',
      fullName: 'Le Khanh Trinh',
      role: 'Vice Director',
      des: 'Mr. Trình holds almost 14 years of experience in the technology industry, demonstrating excellence in his field and displaying adaptability in management. First impressions of Mr. Trình often highlight his perseverance, passion for technology, and diligence. He excels as an expert in supporting teams to tackle challenging technical issues and implement cutting-edge technologies.'
    },
    {
      name: 'Anh Nam',
      fullName: 'Nguyen Van Nam',
      role: 'CTO',
      des: 'Mr. Nam is a Ph.D. holder specializing in artificial intelligence and a lecturer at the Department of Computer Science, Faculty of Computer Science and Engineering at Thuy Loi University, formerly affiliated with the National University. Currently serving as an AI specialist for Viettel, while also working in some labs. As CTO at 1bitlab, he contributes innovative ideas to projects and research, making significant and positive contributions to the field for the company.'
    },
    {
      name: 'Anh Diện',
      fullName: 'Le Van Dien',
      role: 'Project Manager',
      des: 'Mr. Diện exhibits an intense passion and persistence in both his profound love for photography and his unwavering dedication to his work. Known for his meticulousness and careful attention to detail, he consistently earns high praise and trust from clients. With over 8 years of experience in the technology field, Diện has extensive expertise in working with various programming languages.'
    },
    {
      name: 'Anh Dũng',
      fullName: 'Trinh Dinh Dung',
      role: 'Project Manager',
      des: "With over 8 years of industry experience, Mr. Dũng is highly skilled across multiple programming languages. He possesses a keen sense of aesthetics for interface design and is an expert in utilizing various work-support tools. Mr. Dũng's upbeat and proactive nature, coupled with his prompt and humorous approach to work, earns him unwavering trust from clients."
    },
    {
      name: 'Anh Đại',
      fullName: 'Nguyen The Dai',
      role: 'Project Manager',
      des: 'Mr. Đại is a perfectionist, representing the GenZ cohort, and adeptly balances work and life. With a sharp and agile mindset, Đại proactively engages with modern technologies, quickly grasping trends and preferences. Despite his youthfulness, his mature and thoughtful thinking ensures excellent completion of tasks, meeting stringent requirements with precision.'
    },
    {
      name: 'Chị Nhài',
      fullName: 'Vu Thi Nhai',
      role: 'Sales Executive',
      des: 'At first glance, Nhài appears as a beautiful, youthful, and delicate woman. However, in her professional role, she is recognized as a resilient, astute individual with exceptional communication skills in both English and Japanese. She adeptly coordinates teams to swiftly and accurately meet client needs.'
    },
    {
      name: 'Chị Ngọc',
      fullName: 'Phan Hong Ngoc',
      role: 'Tester',
      des: 'Ngọc is a prime example of an Otaku - she can spend hours discussing anime, manga, and novels. Similarly dedicated in her work, many clients jest that she might not sleep to ensure the best delivery to customers in her role as a tester. Ngọc has proven herself to be diligent, proactive, highly productive, possessing excellent communication skills in Japanese, and consistently earns trust and endearment from clients.'
    }
  ];

  return (
    <LayoutCommon>
      <OurTeamsWrapper>
        <div className='about-info-type-2'>
          {t('aboutUs.foundersLeaders.title')}
        </div>
        <div className='title-string'>
          {parse(t('aboutUs.foundersLeaders.subTitle'))}
        </div>
        <Row gutter={[16, 16]}>
          {dataPerson?.map((data, i) => (
            <Col
              key={i}
              xl={6}
              lg={8}
              md={12}
              sm={24}
              xs={24}
              className='flex justify-center'
            >
              <div
                className='mb-4 flex flex-col items-center grid-item people h-[390px] w-[284px]'
                style={{
                  position: 'relative',
                  zIndex: dataPerson.length - i
                }}
              >
                <img
                  className='w-full h-full'
                  src={`../images/aboutUs/person/${data?.name}.png`}
                  alt='empty'
                  style={{ opacity: 0 }}
                />
                <div className='absolute shadow-md rounded-lg cursor-pointer'>
                  <img
                    className='w-full h-[284px] rounded-t-lg'
                    src={`../images/aboutUs/person/${data?.name}.png`}
                    alt=''
                  />
                  <div className='col-member-name text-center font-medium'>
                    {data.fullName}
                  </div>
                  <div className='col-member-major text-center'>
                    {data.role}
                  </div>
                  <div className='description-overlay'>
                    <p className='description '>{data.des}</p>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </OurTeamsWrapper>
    </LayoutCommon>
  );
};

export default OurTeams;
