import React from 'react';
import Button from '~/components/Button';
import { HowToApplyBox } from '../styled';

const HowToApply = () => {
  return (
    <HowToApplyBox>
      <Button style={{ width: '100%' }} name=' '>
        Nộp CV ứng tuyển
      </Button>
      <div className='info-box'>
        <div className='section'>
          <div>Mức lương:</div>
          <p>Thỏa thuận</p>
        </div>
        <div className='section'>
          <div>Thời gian làm việc:</div>
          <p>Toàn thời gian</p>
        </div>
        <div className='section'>
          <div>Cấp bậc:</div>
          <p>Fresher</p>
        </div>
        <div className='section'>
          <div>Thời hạn nộp hồ sơ:</div>
          <p>12/12/2022</p>
        </div>
      </div>
    </HowToApplyBox>
  );
};

export default HowToApply;
