import { ConfigProvider } from 'antd';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ContainerWrapper } from './MainLayout/styled';

const AdminSystem = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          controlHeight: 40,
          borderRadius: 10,
          colorPrimary: '#FF5400'
        },

        components: {
          Button: {
            algorithm: true,
            autoInsertSpace: false
          }
        },

        hashed: false
      }}
    >
      <ContainerWrapper>
        <Suspense fallback={null}>
          <Outlet />
        </Suspense>
      </ContainerWrapper>
    </ConfigProvider>
  );
};

export default AdminSystem;
