import {
  CHANGE_LOCALE,
  SWITCH_THEME,
  CHANGE_SERVICE,
  CHANGE_DETAIL
} from '~/common/constants/ThemeConstant';
import { THEME_CONFIG } from '~/common/theme/config';

const initTheme = {
  ...THEME_CONFIG
};

const theme = (state = initTheme, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale
      };

    case SWITCH_THEME:
      return {
        ...state,
        currentTheme: action.currentTheme
      };

    case CHANGE_SERVICE:
      return {
        ...state,
        service: action.service
      };
    case CHANGE_DETAIL:
      localStorage.setItem('portfolioData', JSON.stringify(action.portfolio));
      return {
        ...state,
        portfolio: action.portfolio
      };
    default:
      return state;
  }
};

export default theme;
