export const data = [
  {
    image: process.env.PUBLIC_URL + '/images/explore/news/news1.png',
    time: '15/09/2022',
    content:
      'Lorem ipsum dolor sit amet, consecte tur adipiscing elit. Ultrices blandit pelle ntesque nibh arcu elementum odio justo. Rhoncus.',
    title: 'New Skill With The Height Quality Camera Lens Fashion',
    domain: 'FASHION'
  },
  {
    image: process.env.PUBLIC_URL + '/images/explore/news/news2.png',
    time: '15/09/2022',
    content:
      'Lorem ipsum dolor sit amet, consecte tur adipiscing elit. Ultrices blandit pelle ntesque nibh arcu elementum odio justo. Rhoncus.',
    title: 'New Skill With The Height Quality Camera Lens Fashion',
    domain: 'FASHION'
  },
  {
    image: process.env.PUBLIC_URL + '/images/explore/news/news3.png',
    time: '15/09/2022',
    content:
      'Lorem ipsum dolor sit amet, consecte tur adipiscing elit. Ultrices blandit pelle ntesque nibh arcu elementum odio justo. Rhoncus.',
    title: 'New Skill With The Height Quality Camera Lens Fashion',
    domain: 'FASHION'
  },
  {
    image: process.env.PUBLIC_URL + '/images/explore/news/news4.png',
    time: '15/09/2022',
    content:
      'Lorem ipsum dolor sit amet, consecte tur adipiscing elit. Ultrices blandit pelle ntesque nibh arcu elementum odio justo. Rhoncus.',
    title: 'New Skill With The Height Quality Camera Lens Fashion',
    domain: 'FASHION'
  },
  {
    image: process.env.PUBLIC_URL + '/images/explore/news/news5.png',
    time: '15/09/2022',
    content:
      'Lorem ipsum dolor sit amet, consecte tur adipiscing elit. Ultrices blandit pelle ntesque nibh arcu elementum odio justo. Rhoncus.',
    title: 'New Skill With The Height Quality Camera Lens Fashion',
    domain: 'FASHION'
  }
];
