import React from 'react';
import { TechniqueWrapper } from '../styled';
import { Col, Row } from 'antd';
import { LayoutCommon } from '../../../styles/genaralStyled';
import FoundingTeam from './FoundingTeam';

const Technique = (props) => {
  const { t } = props;
  return (
    <TechniqueWrapper>
      <LayoutCommon>
        <Row gutter={30} style={{ padding: '77px 0 50px' }}>
          <Col xl={12} md={12} sm={12} xs={24}>
            <img
              src={process.env.PUBLIC_URL + 'images/aboutUs/About1BITLAB.png'}
              alt=''
              width={'100%'}
            />
          </Col>
          <Col xl={12} md={12} sm={12} xs={24}>
            <div className='capability'>{t(`aboutUs.whoAreWe.title`)}</div>
            <div className='capability-title'>
              {t(`aboutUs.whoAreWe.subTitle`)}
            </div>
            <div className='capability-main'>{t(`aboutUs.whoAreWe.des`)}</div>
            <Row gutter={12}>
              <Col span={7}>
                <div className='progress-cicurla'>
                  <div className='progress-cicurla-number'>35+</div>
                </div>
                <div className='progress-cicurla-name'>
                  {t(`aboutUs.whoAreWe.experiencedDevelopers`)}
                </div>
              </Col>
              <Col span={7}>
                <div className='progress-cicurla'>
                  <div className='progress-cicurla-number'>80%</div>
                </div>
                <div className='progress-cicurla-name'>
                  {t(`aboutUs.whoAreWe.loyalCustomers`)}
                </div>
              </Col>
              <Col span={7}>
                <div className='progress-cicurla'>
                  <div className='progress-cicurla-number'>99%</div>
                </div>
                <div className='progress-cicurla-name'>
                  {t(`aboutUs.whoAreWe.CustomerRate`)}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </LayoutCommon>

      <FoundingTeam t={t} />
    </TechniqueWrapper>
  );
};

export default Technique;
