export const dataCategoriManage = [
  {
    icon: 'DataBackup',
    title: 'Data backup and recovery',
    des: [
      'Backup your data regularly and help you recover it in case of a disaster.',
      'Maintain and organize your servers to enhance your search and collaboration capabilities.',
      'Protect your data from corruption, loss or unauthorized access.'
    ]
  },
  {
    icon: 'TechnicalSupport',
    title: 'Technical support to staff',
    des: [
      'Maintain your technology and provide you with devices, such as laptops or phones.',
      'Answer your questions and help you use these devices effectively.',
      'Manage and secure your cloud services and resolve any technical issues or requests.'
    ]
  },
  {
    icon: 'HelpDesk',
    title: 'Help Desk and Technical Support',
    des: [
      'Backup data regularly and create disaster recovery plans to restore data in case of a disaster.',
      'Provide end-user support for employees and technical support for IT systems, both remotely and on-site.'
    ]
  },
  {
    icon: 'ITstrategy',
    title: 'IT strategy consulting Services',
    des: [
      'Design and implement IT strategies that align with your business goals and challenges.',
      'Give advise on best practices and industry standards for IT management.'
    ]
  },
  {
    icon: 'CloudServices',
    title: 'Cloud Services',
    des: [
      'Manage and maintain your cloud-based infrastructure, such as servers and storage.',
      'Provide PaaS solutions suit your needs.'
    ]
  }
];
export const advantages = [
  {
    title: 'Boosting Productivity',
    des: ['Reduce workload for employees to focus on their core duties']
  },
  {
    title: 'Preventing Downtime and Disruptions',
    des: [
      'Keep your online systems running smoothly by monitoring, fixing',
      'Protecting them from emergencies and risks'
    ]
  },

  {
    title: 'Saving Operational Costs',
    des: [
      'Reduce expenses and budget better by charging a fixed monthly fee and eliminating the costs of an internal team'
    ]
  }
];
