import React from 'react';
import PropTypes from 'prop-types';
import { FoundingTeamWrapper, FoundingWrapper } from '~/pages/AboutUs/styled';
import { LayoutCommon } from '~/styles/genaralStyled';
import { Col, Row } from 'antd';

const FoundingTeam = (props) => {
  const { t } = props;

  return (
    <div className='w-full'>
      <LayoutCommon>
        <FoundingTeamWrapper
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/aboutUs/backgroundGroupLeader.png)`
          }}
        >
          <Row gutter={30}>
            <Col
              xl={14}
              md={14}
              sm={14}
              xs={24}
              className='text-justify flex flex-col justify-center'
            >
              <div className='capabilityFounding'>
                {t('aboutUs.foundingTeam.title')}
              </div>
              <div className='founding-title'>
                {t('aboutUs.foundingTeam.subTitle')}
              </div>
              <div className='des-founding mb-4'>
                {t('aboutUs.foundingTeam.des1')}
              </div>
            </Col>
            <Col xl={10} md={10} sm={10} xs={24}>
              <div
                className='flex justify-center items-center'
                style={{ position: 'relative', height: '100%' }}
              >
                <img
                  style={{ height: '470px', width: '530px' }}
                  src='../images/aboutUs/FoundingTeam/group_leader.png'
                  alt=''
                />
              </div>
            </Col>
          </Row>
        </FoundingTeamWrapper>
        <FoundingWrapper>
          <Row gutter={24}>
            <Col xl={12} md={12} sm={12} xs={24} className='mb-[20px]'>
              <div className='border '>
                <img
                  className='responsive-image'
                  src='../images/aboutUs/FoundingTeam/Vision.png'
                  alt=''
                />
                <div>
                  <div className='title-content'>
                    {t('aboutUs.vision.title')}
                  </div>
                  <div className='des-founding'>{t('aboutUs.vision.des')}</div>
                </div>
              </div>
            </Col>

            <Col xl={12} md={12} sm={12} xs={24}>
              <div className='border'>
                <img
                  className='responsive-image'
                  src='../images/aboutUs/FoundingTeam/Mission.png'
                  alt=''
                />
                <div>
                  <div className='title-content'>
                    {t('aboutUs.mission.title')}
                  </div>
                  <div className='des-founding'>{t('aboutUs.mission.des')}</div>
                </div>
              </div>
            </Col>
          </Row>
        </FoundingWrapper>
      </LayoutCommon>
    </div>
  );
};

FoundingTeam.propTypes = {
  style: PropTypes.object
};

export default FoundingTeam;
