import React from 'react';
import { LayoutCommon } from '../../styles/genaralStyled';
import Technique from './Technique';
import { AboutUsWrapper } from './styled';
// import OurTeams from './OurTeams';
import { useTranslation } from '~/common/hooks/useTranslation';
import CompanyBranch from './CompanyBranch';
import OurTeams from './OurTeams';

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <AboutUsWrapper>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/aboutUs/AboutUs.png)`
        }}
        className='bg-about-us'
      >
        <LayoutCommon>
          <div className='main-title md:pt-[120px]'>{t('aboutUs.title')}</div>
        </LayoutCommon>
      </div>
      <Technique t={t} />
      {/* <ToDoList style={{ paddingTop: '50px' }} /> */}
      <OurTeams t={t} />
      <CompanyBranch t={t} />
    </AboutUsWrapper>
  );
};

export default AboutUs;
