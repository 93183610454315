import { Col, Row } from 'antd';
import React from 'react';
import { LayoutCommon } from '~/styles/genaralStyled';
import Category from './components/Category';
import NewsItem from './components/NewsItem';
import { ExploreBox } from './styled';

const Explore = () => {
  return (
    <ExploreBox>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/aboutUs/bg-about-us.png)`
        }}
        className='bg-top'
      >
        <LayoutCommon>
          <div className='main-title' style={{ paddingTop: '120px' }}>
            News
          </div>
        </LayoutCommon>
      </div>
      <LayoutCommon>
        <Row
          gutter={24}
          style={{ paddingTop: '40px' }}
          className='flex flex-col-reverse md:flex-row'
        >
          <Col xl={18} md={18} sm={24} xs={24}>
            <NewsItem />
          </Col>
          <Col xl={6} md={6} sm={24} xs={24} className='mb-4 md:mb-0'>
            <Category />
          </Col>
        </Row>
      </LayoutCommon>
    </ExploreBox>
  );
};

export default Explore;
