import styled from 'styled-components';
export const RelevantProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 60px;
  margin: 0 12px;

  .titlehover {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 10px;
    color: #fff;
    width: 95%;
    height: 95%;
    background: linear-gradient(
      90deg,
      rgba(255, 84, 0, 0.9) 0%,
      rgba(255, 138, 0, 0.9) 100%
    );
  }
  .temp-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .project:hover .titlehover {
    display: block;
    padding: 20%;
    cursor: pointer;
  }

  .our-project {
    font-weight: 600;
    font-size: 24px;
    line-height: 59px;
    color: #373737;
    margin-bottom: 18px;
  }

  .box-container {
    width: 300px;
    padding: 12px;
    height: 100%;

    &-comment {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7b7b7b;
      margin: 14px 0;
    }
  }

  .slick-slider {
    max-width: 1200px;
  }

  .client-talk {
    font-weight: 600;
    font-size: 24px;
    line-height: 59px;
    color: black;
  }

  .user {
    gap: 20px;

    &-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: #ff5300;
    }

    &-position {
      font-weight: 300;
      font-size: 12.8px;
      line-height: 19px;
      color: #7b7b7b;
    }
  }
  //Ipad

  @media screen and (min-width: 768px) {
    padding: 58px 0 90px;
    .client-talk {
      padding: 15px 0 25px;
      font-size: 36px;
      line-height: 59px;
    }
  }
`;

export const RelevantWrapper = styled.div`
  height: 543px;
  margin: 87px 0;
  background-position: center;
  background-repeat: no-repeat;
`;
