import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { LayoutCommon } from '../../../../styles/genaralStyled';
import { dataPortfolio } from './../dataPortfolio';
import { RelevantWrapper, RelevantProjectWrapper } from './styled';
import { useTheme } from './../../../../common/theme/redux/hooks/useTheme';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '~/routes/route.constant';
import SvgIcon from '~/components/SvgIcon';

const ClientMind = (props) => {
  const { t } = props;
  const { actions } = useTheme();
  const navigate = useNavigate();

  const handleClick = (data) => {
    actions.onChangeDetail(data);
    navigate(ROUTE_PATH.DETAIL_PORTFOLIO);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <RelevantWrapper>
      <LayoutCommon>
        <RelevantProjectWrapper>
          <div className='justify-center flex gap-2.5 mt-11 px-5 max-md:mt-10'>
            <div className='about-info-type-2'>
              {t('caseStudy.portfolio.title')}
            </div>
          </div>
          <div>
            <div className='our-project'>
              {t('caseStudy.portfolio.titleDetail')}
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <Splide
              aria-label='My Favorite Images'
              options={{
                width: '100%',
                arrows: true,
                pagination: true,
                lazyLoad: true,
                perPage: 3,
                gap: 2,
                breakpoints: {
                  1000: {
                    perPage: 2
                  },
                  600: {
                    perPage: 1
                  }
                }
              }}
            >
              {dataPortfolio.map((category) =>
                category.childrend.map((data, itemIndex) => (
                  <SplideSlide
                    key={itemIndex}
                    className='box-container '
                    onClick={() => handleClick(data)}
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    <div className='project'>
                      <img
                        src={`${data?.img}`}
                        alt=''
                        className='temp-img cp'
                      />
                      <div className='titlehover text-center '>
                        <div className='flex flex-col justify-center items-center'>
                          <SvgIcon name='linkLine' />
                          <p className='font-worksans text-[20px] md:text-[23px]'>
                            {data.title}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                ))
              )}
            </Splide>
          </div>
        </RelevantProjectWrapper>
      </LayoutCommon>
    </RelevantWrapper>
  );
};

export default ClientMind;
