import styled from 'styled-components';

export const TechniqueWrapper = styled.div`
  .capability {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ff5300;
    margin-top: 20px;
    position: relative;
    display: inline;
    &::after {
      content: '';
      width: 40px;
      height: 1px;
      position: absolute;
      right: -50px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #ff5300;
    }
    &-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #373737;
      margin-top: 15px;
    }

    &-main,
    &-sub {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #7b7b7b;
      margin: 30px 0;
    }

    &-sub {
    }
  }

  .progress-cicurla {
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ff5400;
    border-radius: 50%;

    &-number {
      background: #fff7f4;
      width: 64px;
      border-radius: 110px;
      height: 64px;
      margin: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 47px;
      color: #ff5400;
    }

    &-name {
      margin-top: 15px;
      text-align: center;
      font-weight: 300;
      font-size: 11px;
      line-height: 19px;
      color: #373737;
    }
  }

  .vision {
    font-weight: 600;
    font-size: 24px;
    line-height: 59px;
    color: #0085d6;
    padding-bottom: 7px;
  }

  .content {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    color: #7b7b7b;
  }

  .img-partner {
    position: relative;
    bottom: -100px;
  }

  //Ipad

  @media screen and (min-width: 768px) {
    .capability {
      margin: 0;

      margin-top: 20px;
      &-title {
        font-size: 36px;
        line-height: 59px;
      }

      &-main,
      &-sub {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .progress-cicurla {
      height: 100%;
      &-number {
        width: 110px;
        height: 110px;
        font-size: 31.25px;
        line-height: 47px;
      }

      &-name {
        font-size: 12.8px;
        line-height: 19px;
      }
    }

    .vision {
      font-size: 36px;
      line-height: 59px;
    }
  }
`;

export const AboutUsWrapper = styled.div`
  .bg-about-us {
    height: 100px;
    padding: 80px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  @media screen and (min-width: 768px) {
    .bg-about-us {
      height: 400px;
    }
  }
`;

export const OurTeamsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 77px 0 110px;
  .ant-row {
    padding: 0;
  }
  .description {
    color: #595959;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .description-overlay {
    display: none;
    top: 0;
    left: 0;
    width: 284px;
    height: auto;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    text-align: left;
  }

  .people:hover .description-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    width: 284px !important;
    height: auto;
    box-sizing: border-box;
    border-radius: 10px;
    text-align: left;
  }

  .title-string {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #373737;
    padding: 8px 0 25px;
    div {
      color: #ff5300;
      margin: 0 10px;
    }
  }

  .col-member {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    /* Effect-1 */

    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    &-name {
      font-weight: 500;
      font-size: 20px;
      line-height: 123.6%;
      padding: 25px 0 13px;
      color: #000000;
    }

    &-major {
      font-weight: 400;
      font-size: 16px;
      line-height: 150.6%;
      color: #7b7b7b;
      padding-bottom: 10px;
    }
  }

  //Ipad

  @media screen and (min-width: 768px) {
    padding: 50px 0;

    .ant-row {
      padding: 0;
    }

    .title-string {
      font-size: 39.06px;
      line-height: 123.6%;
    }
  }
`;

export const CompanyBranchArrow = styled.div`
  /* .splide__arrow {
    background-color: #ffffff;
    color: #333333;
    border: 2px solid #333333;
    border-radius: 50%;
    padding: 10px;
  }

  .splide__arrow:hover {
    background-color: #ff5300;
    color: #fff;
  } */
`;

export const CompanyBranchWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0;

  //Ipad

  .about-us-icon svg path {
    fill: none;
  }
  .about-us-icon:hover svg {
    transform: scale(1.2);
  }
  @media screen and (min-width: 768px) {
    padding: 50px 0;

    .ant-row {
      padding: 0;
    }

    .title-string {
      font-size: 39.06px;
      line-height: 123.6%;
    }
  }
`;

export const FoundingTeamWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  .todo-item {
    margin: 12px;
    padding: 38px 24px;
    background: #fff;
    flex-direction: column;
    box-shadow: 0px 1px 20px rgba(0, 36, 125, 0.06);
    border-radius: 10px;

    :hover {
      cursor: pointer;
      background: linear-gradient(90deg, #ff5400 0%, #ff8a00 100%),
        linear-gradient(90deg, #0085d6 0%, #006fd6 100%), #0095ef;
    }
  }
  .capabilityFounding {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ff5300;
    margin-top: 20px;
    position: relative;
    display: inline;
    &::after {
      content: '';
      width: 40px;
      height: 1px;
      position: absolute;
      // right: 100px;
      left: 220px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #ff5300;
    }
    &-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #373737;
      margin-top: 15px;
    }

    &-main,
    &-sub {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #7b7b7b;
      margin: 30px 0;
    }

    &-sub {
    }
  }

  .item-title {
    margin: 12px 0 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
  }

  .item-content {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .founding-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 59px;
    color: #373737;
    margin: 8px 0 25px;
  }
  .title-content {
    font-weight: 600;
    font-size: 24px;
    line-height: normal;
    color: #ff5400;
    margin: 8px 0 25px;
  }
  .des-founding {
    font-size: 16px;
    // font-style: italic;
    font-family: Poppins;
    color: #595959;
  }

  @media screen and (min-width: 768px) {
    .founding-title {
      font-size: 28px;
      line-height: 59px;
    }
    .capabilityFounding {
      margin: 0;

      margin-top: 20px;
      &-title {
        font-size: 36px;
        line-height: 59px;
      }

      &-main,
      &-sub {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;

export const FoundingWrapper = styled.div`
  .border {
    display: flex;
    height: 164px;
    padding: 24px;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    background: #f2f8fb;
  }
  padding-top: 60px;
  .vision {
    display: flex;
    width: 588px;
    padding: 24px;
    align-items: center;
    gap: 16px;
  }
  .mission {
    display: flex;
    width: 588px;
    padding: 24px;
    align-items: center;
    gap: 16px;
  }

  .title-content {
    font-weight: 600;
    font-size: 24px;
    line-height: normal;
    color: #262626;
    // margin: 8px 0 25px;
  }
  .des-founding {
    font-size: 15px;
    font-family: Poppins;
    color: #434343;
  }

  @media screen and (min-width: 768px) {
    .founding-title {
      font-size: 28px;
      line-height: 59px;
    }
  }
  .responsive-image {
    height: 80px;
    width: 80px;
  }
  @media only screen and (max-width: 768px) {
    .responsive-image {
      height: 60px;
      width: 60px;
    }
    .des-founding {
      font-size: 14px;
    }
  }
`;
