import enLang from './entries/en_US';
import jaLang from './entries/ja_JP';

import viLang from './entries/vi_VN';
import twLang from './entries/ta_IN';

const AppLocale = {
  vi: viLang,
  en: enLang,
  ja: jaLang,
  cn: twLang
};

export default AppLocale;
