import React from 'react';
import { JobDetailWrapper } from './styled';
import { LayoutCommon } from '~/styles/genaralStyled';
import { Col, Row } from 'antd';
import Infomation from './components/Infomation';
import HowToApply from './components/HowToApply';

const JobDetail = () => {
  return (
    <JobDetailWrapper>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/aboutUs/bg-about-us.png)`
        }}
        className='bg-top'
      >
        <LayoutCommon>
          <div className='header about-info-type-1'>Career</div>
          <div className='text-title'>NodeJS Developer</div>
        </LayoutCommon>
      </div>
      <LayoutCommon>
        <Row gutter={24} style={{ paddingTop: '40px' }}>
          <Col xl={18} md={18} sm={24} xs={24}>
            <Infomation />
          </Col>
          <Col xl={6} md={6} sm={24} xs={24}>
            <HowToApply />
          </Col>
        </Row>
      </LayoutCommon>
    </JobDetailWrapper>
  );
};

export default JobDetail;
