import styled from 'styled-components';

export const ExploreBox = styled.div`
  background-color: #f2f8fb;
  padding-bottom: 150px;
  .bg-top {
    height: 400px;
    padding-top: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

export const NewsItemBox = styled.div`
  .news-container {
    background-color: #fff;
    margin-bottom: 24px;
    border-radius: 10px;
    cursor: pointer;
  }
  img {
    width: 95%;
    padding-top: 10px;
  }
  .domain {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #ff5400;
  }

  .domain::after {
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    background: #ff5400;
    border-radius: 6px;
    margin: 0 20px;
  }

  .time {
    font-weight: 400;
    font-size: 14px;
    color: #595959;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;

    color: #1f1f1f;
    padding: 12px 0;
  }

  .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: justify;

    /* Neutral/8 */
    color: #595959;
  }

  .col-box {
    padding: 10px;
  }

  @media screen and (min-width: 768px) {
    img {
      padding-top: 0;
      width: auto;
    }

    .col-box {
      padding-top: 33.5px;
      padding-right: 22px;
    }
  }
`;

export const CategoryBox = styled.div`
  margin-bottom: 10px;
  .category {
    margin-top: 24px;
    background-color: #fff;
    padding: 30px 20px 20px 30px;
    border-radius: 10px;

    &-text {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 30px;
    }
  }

  .input-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;

    input {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 0px 10px 16px;
      gap: 10px;
      background: #f2f8fb;
      border-radius: 8px;
    }
  }

  .checkbox {
    display: flex;
    flex-direction: column;

    &-item {
      border-top: 1px solid #f0f0f0;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #1f1f1f;
      padding: 12px 0 12px 0;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        height: 24px;
      }
    }
  }

  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    border-color: #ff5400;
  }

  .ant-checkbox .ant-checkbox-inner:after {
    width: 6.714286px;
    height: 15.142857px;
  }
`;
