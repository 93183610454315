import { gql } from '@apollo/client';

export const SIGN_IN = gql`
  mutation SignIn($input: LoginInput!) {
    signIn(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const GET_REFRESH_TOKEN = gql`
  mutation RefreshToken {
    refreshToken {
      accessToken
      refreshToken
    }
  }
`;

export const LOG_OUT = gql`
  mutation Mutation {
    logOut
  }
`;
