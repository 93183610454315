export const dataCapabiliti = [
  {
    icon: 'mail',
    title: 'Flexibility',
    des: 'We are adaptable in supplying labor, accommodating flexible working hours, allocating resources to suit multiple time zones, and adjusting methodology for 24/7 support.'
  },
  {
    icon: 'edit',
    title: 'Competitive Cost',
    des: 'We offer competitive costs coupled with high-level resources in Vietnam, streamlining operations through effective management tools for continuous process improvement.'
  },
  {
    icon: 'grid',
    title: 'High speed',
    des: 'We swiftly grasp customer needs and market trends while adhering to Rule number 3 in our work.'
  }
];
