import React from 'react';
import { ROUTE_PATH } from '~/routes/route.constant';

export const Languages = [
  {
    label: (
      <img src={process.env.PUBLIC_URL + '/images/VN.png'} width={24} alt='' />
    ),
    value: 'vi'
  },
  {
    label: (
      <img src={process.env.PUBLIC_URL + '/images/UK.png'} width={24} alt='' />
    ),
    value: 'en'
  },
  {
    label: (
      <img src={process.env.PUBLIC_URL + '/images/JP.png'} width={24} alt='' />
    ),
    value: 'ja'
  },
  {
    label: (
      <img src={process.env.PUBLIC_URL + '/images/CN.png'} width={24} alt='' />
    ),
    value: 'cn'
  }
];

export const listServices = [
  {
    img: process.env.PUBLIC_URL + '/images/i5.svg',
    title: 'On-demand Software Development',
    description: 'Crafting software solutions for every need',
    path: ROUTE_PATH.ON_DEMAND_SOFTWARE_DEVELOPMENT
  },
  {
    img: process.env.PUBLIC_URL + '/images/i4.svg',
    title: 'Remote Software Engineer Services',
    description: 'Swiftly providing qualified personnel to match your needs',
    path: ROUTE_PATH.REMOTE_SOFTWARE
  },
  {
    img: process.env.PUBLIC_URL + '/images/i7.svg',
    title: 'R&D Services',
    description:
      'Consulting on advanced technology fosters the growth of Startups & SMEs',
    path: ROUTE_PATH.RD_SOLUTION
  },
  {
    img: process.env.PUBLIC_URL + '/images/i6.svg',
    title: 'Managed Services',
    description: 'Ensuring uninterrupted excellence in every operation',
    path: ROUTE_PATH.SERVICES
  },
  {
    img: process.env.PUBLIC_URL + '/images/i8.svg',
    title: 'Game Development',
    description: 'Delivering market-leading, captivating game experiences',
    path: ROUTE_PATH.GAME_DEVELOPMENT
  }
  // {
  //   img: process.env.PUBLIC_URL + '/images/i3.svg',
  //   title: 'DX Consulting',
  //   description: 'Unlock potential by a new approach'
  // }
];
