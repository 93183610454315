export const data = [
  {
    image: process.env.PUBLIC_URL + '/images/jobItem/job1.png',
    time: '2022-05-10',
    title: 'NodeJS Developer',
    price: '15-20 triệu'
  },
  {
    image: process.env.PUBLIC_URL + '/images/jobItem/job2.png',
    time: '2022-05-10',
    title: 'NodeJS Developer',
    price: '15-20 triệu'
  },
  {
    image: process.env.PUBLIC_URL + '/images/jobItem/job3.png',
    time: '2022-05-10',
    title: 'NodeJS Developer',
    price: '15-20 triệu'
  },
  {
    image: process.env.PUBLIC_URL + '/images/jobItem/job4.png',
    time: '2022-05-10',
    title: 'NodeJS Developer',
    price: '15-20 triệu'
  }
];
