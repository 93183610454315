import { useLazyQuery } from '@apollo/client';
import { Button, Col, Empty, Input, Pagination, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DATE_FORMAT_DD_MM_YYYY_WITH_SLASH } from '~/common/constants';
import { useTranslation } from '~/common/hooks/useTranslation';
import {
  GET_BLOG_CATEGORIES,
  GET_TAGS,
  USER_GET_LIST_BLOG
} from '~/graphql/Blog.graphql';
import { parseQueryString } from '~/helpers/utils';
import { LayoutCommon } from '~/styles/genaralStyled';
import { TechBlogsWrapper } from './styled';

function TechBlogs() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [_, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

  const [mainNew, setMainNew] = useState({});
  const [isLoaded, setIsLoaded] = useState(true);
  const [tags, setTags] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);
  const [initValues, setInitValues] = useState({
    status: 'ACTIVE'
  });

  const [pageParams, setPageParams] = useState({
    pageSize: 7,
    page: 1
  });

  const [getTags, { loading: loadingTags }] = useLazyQuery(GET_TAGS, {
    onCompleted: (data) => {
      const listTags = data.tags.map((item) => {
        return {
          value: item.title,
          label: item.title
        };
      });
      setTags(listTags);
    }
  });

  const [getBlogCategories, { loading: loadingCategories }] = useLazyQuery(
    GET_BLOG_CATEGORIES,
    {
      onCompleted: (data) => {
        const categories = data.blogCategories.map((item) => {
          return {
            value: item.id,
            label: item.title
          };
        });
        setBlogCategories(categories);
      }
    }
  );

  const [getBlog, { data: blogList, loading: loadingBlog }] = useLazyQuery(
    USER_GET_LIST_BLOG,
    {
      onCompleted: (data) => {
        setMainNew(data.userBlogs[0]);
      }
    }
  );

  const getListBlog = async () => {
    setIsLoaded(false);
    await Promise.all([
      getTags(),
      getBlogCategories(),
      getBlog({
        variables: {
          input: {
            ...initValues,
            ...pageParams,
            ...parseQueryString(search)
          },
          countInput2: {
            ...initValues,
            ...pageParams,
            ...parseQueryString(search)
          }
        }
      })
    ]);
  };

  useEffect(() => {
    setInitValues({
      status: 'ACTIVE',
      ...parseQueryString(search)
    });

    if (isLoaded) getListBlog();
    else
      getBlog({
        variables: {
          input: {
            status: 'ACTIVE',
            ...pageParams,
            ...parseQueryString(search)
          },
          countInput2: {
            status: 'ACTIVE',
            ...pageParams,
            ...parseQueryString(search)
          }
        }
      });
  }, [search]);

  const handleChangePage = (page) => {
    setPageParams({ ...pageParams, page });
    getBlog({
      variables: {
        input: {
          ...initValues,
          ...pageParams,
          page,
          ...parseQueryString(search)
        },
        countInput2: {
          ...initValues,
          ...pageParams,
          page,
          ...parseQueryString(search)
        }
      }
    });
  };

  const onSearch = (input) => {
    setInitValues({ ...initValues, ...input });
    setSearchParams(queryString.stringify({ ...initValues, ...input }));
    setPageParams({ ...pageParams, page: 1 });
  };

  const goToPostDetail = (slug) => {
    navigate(`/${slug}`);
  };

  return (
    <TechBlogsWrapper>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/expertises/bg-expertises.png)`
        }}
        className='bg-top'
      >
        <LayoutCommon>
          <div className='main-title md:pt-[120px]'>{t('blogs.title')}</div>
        </LayoutCommon>
      </div>
      <Spin spinning={loadingBlog || loadingTags || loadingCategories}>
        <LayoutCommon>
          {blogList?.count > 0 && (
            <div className='mt-[80px]'>
              <Row gutter={30}>
                <Col xl={12} md={12} sm={24} xs={24}>
                  <img
                    src={process.env.REACT_APP_API_UPLOAD + mainNew?.image}
                    alt=''
                    className='rounded-md w-full h-full max-h-[360px] object-cover'
                  />
                </Col>
                <Col xl={12} md={12} sm={24} xs={24}>
                  <div className='flex mt-4 md:mt-0'>
                    <div
                      className={`text-[#FF5400] relative mr-12 after:absolute after:top-1/2 after:-right-16 after:-translate-x-1/2 after:content-[''] after:w-10 after:h-[1px] after:bg-[#FF5400] `}
                    >
                      {mainNew?.category?.title}&nbsp;
                    </div>
                    <div>
                      {dayjs(mainNew?.createdAt).format(
                        DATE_FORMAT_DD_MM_YYYY_WITH_SLASH
                      )}{' '}
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      goToPostDetail(mainNew?.slug)
                    }
                    className='text-xl xl:text-2xl font-semibold  mt-[18px] mb-[20px] xl:mb-[30px] text-[#373737] line-clamp-3 cursor-pointer'
                  >
                    {mainNew?.title}
                  </div>
                  <div
                    className='line-clamp-3 img-none leading-[1.85]'
                    dangerouslySetInnerHTML={{ __html: mainNew?.content }}
                  ></div>
                </Col>
              </Row>
            </div>
          )}
          <Row gutter={30} className='mt-20'>
            <Col xl={18} md={24} sm={24} xs={24}>
              <Row gutter={[30, 30]} className='flex justify-center'>
                {blogList?.userBlogs.length > 0 ? (
                  blogList?.userBlogs?.map((item, idx) => {
                    return (
                      idx > 0 && (
                        <Col xl={12} md={12} sm={24} xs={24}>
                          <div
                            className='shadow-[0px_1px_10px_0px_#0000001A] rounded-[10px] cursor-pointer xl:h-full'
                            onClick={() =>
                              goToPostDetail(item?.slug)
                            }
                          >
                            <img
                              src={
                                process.env.REACT_APP_API_UPLOAD + item?.image
                              }
                              alt=''
                              className='xl:max-h-[245px] rounded-t-[10px] w-full object-cover'
                            />

                            <div className='px-4 pt-[26px] pb-[20px]'>
                              <div className='mb-4 text-xl font-semibold text-[#373737] leading-[24.72px] line-clamp-3'>
                                {item?.title}
                              </div>
                              <div className='flex'>
                                <div
                                  className={`mb-5 text-[#FF5400] relative mr-12 after:absolute after:top-1/2 after:-right-16 after:-translate-x-1/2 after:content-[''] after:w-10 after:h-[1px] after:bg-[#FF5400]`}
                                >
                                  {item?.category?.title}&nbsp;
                                </div>
                                <div>
                                  {dayjs(item?.createdAt).format(
                                    DATE_FORMAT_DD_MM_YYYY_WITH_SLASH
                                  )}
                                </div>
                              </div>
                              <div
                                className='line-clamp-3 img-none leading-[1.85]'
                                dangerouslySetInnerHTML={{
                                  __html: item?.content
                                }}
                              ></div>
                            </div>
                          </div>
                        </Col>
                      )
                    );
                  })
                ) : (
                  <Empty description={'No posts found'} />
                )}
              </Row>
            </Col>
            <Col xl={6} md={24} sm={24} xs={24}>
              <div>
                <div className='shadow-[0_5px_5px_0_#0000001A] mb-[33px] mt-10 xl:mt-0 h-10'>
                  <Input
                    placeholder='Keyword...'
                    value={initValues.search}
                    onChange={(e) =>
                      setInitValues({
                        ...initValues,
                        search: e.target.value
                      })
                    }
                    suffix={
                      <Button
                        onClick={() => onSearch()}
                        className='button-custom'
                      >
                        Search
                      </Button>
                    }
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') onSearch();
                    }}
                  />
                </div>
                <div className='stack-tag'>CATEGORIES</div>
                <ul className='ml-6 list-disc columns-1 md:columns-2 xl:columns-1'>
                  <li
                    key={'undef'}
                    className={`leading-6 cursor-pointer ${
                      initValues.category === undefined && 'text-[#FF5400]'
                    }`}
                    onClick={() => {
                      onSearch({ category: undefined });
                    }}
                  >
                    All Categories
                  </li>
                  {blogCategories.map((category) => (
                    <li
                      key={category.value}
                      className={`leading-6 cursor-pointer ${
                        initValues.category === category.value &&
                        'text-[#FF5400]'
                      }`}
                      onClick={() => {
                        onSearch({ category: category.value });
                      }}
                    >
                      {category.label}
                    </li>
                  ))}
                </ul>
              </div>
              <div className='stack-tag mt-10'>TAGS</div>
              <div className='flex flex-wrap gap-4'>
                {tags.map((tag) => (
                  <div
                    key={tag.value}
                    className={`px-3 py-2 cursor-pointer flex  items-center rounded-[20px] border border-solid border-[#D9D9D9] ${
                      initValues.tag === tag.value &&
                      'text-[#FF5400] border-[#FF5400]'
                    }`}
                    onClick={() => {
                      onSearch({ tag: tag.value });
                    }}
                  >
                    {tag.label}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
          <Pagination
            className='py-[60px] text-center'
            onChange={handleChangePage}
            current={pageParams.page}
            pageSize={pageParams.pageSize}
            total={blogList?.count}
          />
        </LayoutCommon>
      </Spin>
    </TechBlogsWrapper>
  );
}

export default TechBlogs;
