import antdTW from 'antd/es/locale/ta_IN';
import twMsg from '../locales/ta_IN.json';
import { flattenMessages } from '~/helpers/flatten';

const EnLang = {
  antd: antdTW,
  locale: 'en-US',
  messages: {
    ...flattenMessages(twMsg)
  }
};
export default EnLang;
