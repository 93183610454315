import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  .ant-divider-horizontal {
    margin: 0;
  }

  a {
    color: unset;
  }
`;

export const ContainerWrapper = styled.div`
  .ant-divider-horizontal {
    margin-top: 0;
  }
`;
