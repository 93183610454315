import React from 'react';
import SvgIcon from '../../../components/SvgIcon';
import { LayoutCommon } from '../../../styles/genaralStyled';
import { ContactWrapper } from '../styled';
import { useTranslation } from '~/common/hooks/useTranslation';

const Contact = () => {
  const { t } = useTranslation();
  return (
    <ContactWrapper>
      <LayoutCommon style={{ height: '100%' }}>
        <div className='flex header-page'>
          <div className='flex'>
            <div className='flex'>
              <a
                href={`tel:${t('layout.footer.contact.phoneNumber')}`}
                className='flex !text-[#fff]'
              >
                <SvgIcon name='phone' style={{ marginRight: '10px' }} />
                {t('layout.footer.contact.phoneNumber')}
              </a>
            </div>
            <div className='flex'>
              <a
                href={`mailto:${t('layout.footer.contact.emailAddress')}`}
                className='flex !text-[#fff]'
              >
                <SvgIcon name='mail' style={{ margin: '0 10px 0 25px' }} />
                {t('layout.footer.contact.emailAddress')}
              </a>
            </div>
          </div>
          <div className='flex'>
            <a
              href='https://www.facebook.com/1bitlabTechnologyJSC'
              target='blank'
            >
              <SvgIcon name='fb-circle' style={{ marginRight: '25px' }} />
            </a>
            <a href='https://twitter.com/1bitlabtechjsc' target='blank'>
              <SvgIcon name='tw-circle' style={{ marginRight: '25px' }} />
            </a>
            <a
              href='https://www.linkedin.com/company/1bitlab-technology/'
              target='blank'
            >
              <SvgIcon name='linkedin-circle' />
            </a>
          </div>
        </div>
      </LayoutCommon>
    </ContactWrapper>
  );
};

export default Contact;
