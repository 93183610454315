import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '~/common/hooks/useTranslation';
import Button from '~/components/Button';
import { ROUTE_PATH } from '~/routes/route.constant';
import { LayoutCommon } from '../../styles/genaralStyled';
import Capabiliti from './components/Capabiliti';
import Experience from './components/Experience';
import OutPartners from './components/OutPartners';
import ToDoList from './components/ToDoList';
import { ExperienceWrapper, HeaderWrapper, HomePageWrapper } from './styled';
import ClientMind from './components/ClientMind';

function HomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <HomePageWrapper>
      <HeaderWrapper
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/home_edit.png)`
        }}
        className='min-h-[800px] md:min-h-[480px] lg:min-h-[520px] 2xl:min-h-[740px]'
      >
        <LayoutCommon className='flex flex-col md:flex-row items-center'>
          <div className='w-full max-w-[593px] pt-5 md:pt-0'>
            <div className='flex flex-col justify-center'>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('homePage.title')
                }}
                className='text-[#FF5400] text-[26px] lg:text-[40px] font-[700]'
              />
              <div className='text-[#597789] text-[18px] font-[600] my-[24px]'>
                {t('homePage.subTitle')}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('homePage.des')
                }}
                className='text-[#262626] text-[15px] font-[400] mb-[24px]'
              />
            </div>
            <div className='flex flex-col min-[440px]:flex-row items-center justify-center my-6 gap-6 lg:justify-start'>
              <Button
                className=''
                onClick={() => {
                  navigate(ROUTE_PATH.ABOUT_US);
                }}
              >
                {t('common.btn.exploreMore')}
              </Button>

              <Button
                backgroundColor='#fff'
                color='#0085D6'
                onClick={() => {
                  navigate(ROUTE_PATH.CONTACT_US);
                }}
              >
                {t('common.btn.contactUs')}
              </Button>
            </div>
          </div>
          <div className='w-full'>
            <img
              src={process.env.PUBLIC_URL + '/images/member.png'}
              width={'100%'}
              alt=''
            />
          </div>
        </LayoutCommon>
      </HeaderWrapper>
      <ToDoList />
      <ExperienceWrapper
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/listService/bg-ser.png)`
        }}
      >
        <LayoutCommon>
          <div className='text-center pt-[100px] md:pt-[120px] min-[3000px]:pt-[200px] min-[5000px]:pt-[250px] '>
            <div className='about-info-type-1'>
              {t('homePage.weService.title')}
            </div>
            <div className='experience-title'>
              {t('homePage.weService.des')}
            </div>
          </div>
          <Experience />
        </LayoutCommon>
      </ExperienceWrapper>
      <OutPartners />

      <LayoutCommon>
        <Capabiliti t={t} />
      </LayoutCommon>

      {/* <Portfolio t={t} /> */}

      <ClientMind t={t} />

      {/* <Explore t={t} /> */}
    </HomePageWrapper>
  );
}

export default HomePage;
