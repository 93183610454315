import styled from 'styled-components';

export const ContactUsWrapper = styled.div`
  .bg-top {
    height: 150px;
    padding-top: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .header {
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */
    text-align: center;
    color: #ffffff;
  }
  @media screen and (min-width: 768px) {
    .bg-top {
      height: 400px;
    }
    .header {
      padding-top: 120px;
    }
  }
`;
export const CardInfoWrapper = styled.div`
  .btn-send {
    margin-top: 20px;
  }

  .ant-select {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 12px 20px; */
    gap: 10px;

    height: 48px;

    /* Gray 5 */

    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 14px;

    .ant-select-selector {
      border: unset;
    }
  }

  .ant-select-focused,
  .ant-select-selector {
    box-shadow: unset !important;
  }

  .ant-input {
    height: 48px;
    font-size: 14px;
  }

  textarea.ant-input {
    height: unset;
  }

  .card-title {
    display: flex;
    height: 54px;

    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    p {
      margin-left: 5px;
      color: #ff5400;
      height: 36px;
      line-height: 36px;
    }
  }

  .sub-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    letter-spacing: 0.01em;

    /* Neutral/8 */

    color: #595959;
    padding: 20px 0;
  }

  @media screen and (min-width: 768px) {
    .card-title {
      display: flex;
      height: 54px;

      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 54px;

      p {
        color: #ff5400;
        height: 54px;
        line-height: 54px;
      }
    }
  }
`;
