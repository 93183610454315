import queryString from 'query-string';
import { ACCESS_TOKEN_KEY } from '~/constants/init.constants';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0
    // , behavior: 'smooth'
  });
};

export const getAuthLocalStorage = () => {
  const authData = localStorage.getItem(ACCESS_TOKEN_KEY);
  return authData && JSON.parse(authData);
};

export const formatMoney = (money) =>
  money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const getAccessToken = () => {
  const data = getAuthLocalStorage();
  return data?.accessToken || '';
};

export const getRefreshToken = () => {
  const data = getAuthLocalStorage();
  return data?.refreshToken || '';
};

export const parseQueryString = (object) => {
  const parsed = queryString.parse(object);

  for (const key in parsed) {
    if (parsed[key] === 'undefined') {
      parsed[key] = undefined;
    }
  }

  return parsed;
};
