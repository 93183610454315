import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { Decal, Float, OrbitControls, Preload, useTexture } from '@react-three/drei';

const Ball = (props) => {
    const [decal] = useTexture([props.imgUrl]);
    return (
        <Float speed={4} rotationIntensity={1} floatIntensity={2}>
            <ambientLight intensity={2.3} />
            <directionalLight position={[0, 0, 0.05]} />
            <mesh castShadow receiveShadow scale={2.3}>
                <sphereGeometry args={[1, 16, 16]} />
                <meshStandardMaterial color='#ff6d25' polygonOffset polygonOffsetFactor={-2} flatShading />
                <Decal position={[0, 0, 1]} rotation={[2 * Math.PI, 0, 6.25]} flatShading map={decal} scale={0.7}/>
            </mesh>
        </Float>
    )
}

const BallCanvas = ({ icon }) => {
    return (
        <Canvas gl={{ preserveDrawingBuffer: true}}>
            <Suspense fallback={<>Loading</>}>
                <OrbitControls enableZoom={false} enablePan={false}/>
                <Ball imgUrl={icon}/>
            </Suspense>
            <Preload all />
        </Canvas>
    )
}

export default BallCanvas