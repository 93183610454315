/* eslint-disable no-loop-func */
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  Observable
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { toast } from 'sonner';
import { ACCESS_TOKEN_KEY } from '~/constants/init.constants';
import { GET_REFRESH_TOKEN } from '~/graphql/Authentication.graphql';
import { getAccessToken, getRefreshToken } from '~/helpers/utils';
import { ROUTE_PATH } from '~/routes/route.constant';

function isRefreshRequest(operation) {
  return operation.operationName === 'RefreshToken';
}

function returnTokenDependingOnOperation(operation) {
  if (isRefreshRequest(operation)) return getRefreshToken() || '';
  else return getAccessToken() || '';
}

const authLink = setContext((operation, { headers }) => {
  let token = returnTokenDependingOnOperation(operation);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (err.message === 'Unauthorized') {
          const observable = new Observable(async (observer) => {
            (async () => {
              try {
                const accessToken = await refreshToken();
                if (!accessToken) {
                  throw new GraphQLError('Empty AccessToken');
                }
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${accessToken}`
                  }
                });
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                };
                forward(operation).subscribe(subscriber);
              } catch (err) {
                window.location.href = ROUTE_PATH.LOGIN;
                observer.error(err);
              }
            })();
          });
          return observable;
        } else toast.error(err.message);
      }
    }
    if (networkError) toast.error(`Network error: ${networkError}`);
  }
);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    }
  }
});

const refreshToken = async () => {
  try {
    const refreshResolverResponse = await client.mutate({
      mutation: GET_REFRESH_TOKEN
    });

    localStorage.setItem(
      ACCESS_TOKEN_KEY,
      JSON.stringify(refreshResolverResponse?.data?.refreshToken)
    );
    return refreshResolverResponse?.data?.refreshToken?.accessToken;
  } catch (err) {
    localStorage.clear();
    throw err;
  }
};

export { client };
