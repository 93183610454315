import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from '~/common/hooks/useTranslation';
import SvgIcon from '~/components/SvgIcon';
import { listServices } from '~/constants';
import { ROUTE_PATH } from '../../../routes/route.constant';
import { LayoutCommon } from '../../../styles/genaralStyled';
import { MenuWrapper } from '../styled';
import Contact from './Contact';

const MenuTop = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showSidebar, setShowSidebar] = useState(false);
  const [path, setPath] = useState('home');
  const [serviceItem, setServiceItem] = useState(1);
  const [stickyActive, setStickyActive] = useState(false);

  const items = listServices.map((item, idx) => {
    return {
      key: item.title,
      label: (
        <Link
          to={item?.path}
          className={`flex  py-1 ${
            serviceItem === idx + 1 &&
            path === 'service' &&
            ' !text-[#ff5300] w-full'
          }`}
        >
          <SvgIcon name='iconly' />
          <div>{item.title}</div>
        </Link>
      )
    };
  });

  const MenuLink = [
    {
      label: (
        <Link
          onClick={() => setShowSidebar(false)}
          className={`${path === 'home' && '!text-[#ff5300]'}`}
          to={`${ROUTE_PATH.HOME}`}
        >
          {t('layout.navBar.home')}
        </Link>
      ),
      key: ROUTE_PATH.HOME
    },
    {
      // label: <Link to={ROUTE_PATH.SERVICES}>{t('layout.navBar.service')}</Link>,
      label: (
        <Dropdown
          menu={{
            items
          }}
          arrow
          placement='bottom'
          className={`${path === 'service' && '!text-[#ff5300]'}`}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              {t('layout.navBar.service')}
              <CaretDownOutlined />
            </Space>
          </a>
        </Dropdown>
      ),
      key: ROUTE_PATH.SERVICES
    },

    {
      label: (
        <Link
          onClick={() => setShowSidebar(false)}
          className={`${path === 'expertises' && '!text-[#ff5300]'}`}
          to={`${ROUTE_PATH.EXPERTISE}`}
        >
          {t('layout.navBar.expertises')}
        </Link>
      ),
      key: ROUTE_PATH.EXPERTISE
    },
    {
      label: (
        <Link
          onClick={() => setShowSidebar(false)}
          className={`${path === 'portfolio' && '!text-[#ff5300]'}`}
          to={`${ROUTE_PATH.PORTFOLIO}`}
        >
          {t('layout.navBar.portfolio')}
        </Link>
      ),
      key: ROUTE_PATH.PORTFOLIO
    },
    {
      label: (
        <Link
          onClick={() => setShowSidebar(false)}
          className={`${path === 'aboutUs' && '!text-[#ff5300]'}`}
          to={ROUTE_PATH.ABOUT_US}
        >
          {t('layout.navBar.aboutUs')}
        </Link>
      ),
      key: ROUTE_PATH.ABOUT_US
    },

    {
      label: (
        <Link
          onClick={() => setShowSidebar(false)}
          className={`${path === 'contactUs' && '!text-[#ff5300]'}`}
          to={ROUTE_PATH.CONTACT_US}
        >
          {t('layout.navBar.contactUs')}
        </Link>
      ),
      key: ROUTE_PATH.CONTACT_US
    },
    {
      label: (
        <Link
          onClick={() => setShowSidebar(false)}
          className={`${path === 'techBlogs' && '!text-[#ff5300]'}`}
          to={ROUTE_PATH.TECH_BLOGS}
        >
          {t('layout.navBar.blogs')}
        </Link>
      ),
      key: ROUTE_PATH.TECH_BLOGS
    }
  ];

  const handleScroll = () => {
    if (ref.current && ref.current.offsetTop > 0) {
      setStickyActive(true);
    } else setStickyActive(false);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowSidebar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    switch (pathname) {
      case ROUTE_PATH.HOME:
        setPath('home');
        break;
      case ROUTE_PATH.ON_DEMAND_SOFTWARE_DEVELOPMENT:
        setPath('service');
        setServiceItem(1);
        break;
      case ROUTE_PATH.GAME_DEVELOPMENT:
        setPath('service');
        setServiceItem(5);
        break;
      case ROUTE_PATH.RD_SOLUTION:
        setPath('service');
        setServiceItem(3);
        break;
      case ROUTE_PATH.SERVICES:
        setPath('service');
        setServiceItem(4);
        break;
      case ROUTE_PATH.REMOTE_SOFTWARE:
        setPath('service');
        setServiceItem(2);
        break;
      case ROUTE_PATH.EXPERTISE:
        setPath('expertises');
        break;
      case ROUTE_PATH.PORTFOLIO:
        setPath('portfolio');
        break;

      case ROUTE_PATH.ABOUT_US:
        setPath('aboutUs');
        break;
      case ROUTE_PATH.CONTACT_US:
        setPath('contactUs');
        break;
      case ROUTE_PATH.TECH_BLOGS:
        setPath('techBlogs');
        break;

      default:
        setPath('');
        break;
    }
  }, [pathname]);

  return (
    <MenuWrapper ref={ref} isActive={stickyActive}>
      <Contact />

      <LayoutCommon>
        <div className='flex row-top items-center'>
          <img
            src={
              process.env.PUBLIC_URL +
              `/images/${
                stickyActive
                  ? 'logo1bitlab-sticky.png'
                  : 'logo1bitlab-sticky.png'
              }`
            }
            alt=''
            className='logo-company'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(ROUTE_PATH.HOME);
            }}
          />
          <div className='flex' ref={wrapperRef}>
            <div className={`nav-elements  ${showSidebar && 'active'}`}>
              <ul className='w-full'>
                {MenuLink.map((item) => {
                  return (
                    <li
                      // onClick={() => setShowSidebar(false)}
                      className={`item-label ${
                        setStickyActive && 'sticky-color'
                      }`}
                      key={item.key}
                    >
                      {item.label}
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* <Space>
              <SvgIcon
                name='search'
                className={`icon-search  ${stickyActive && 'sticky-color'}`}
              />

              <div className={`nation ${stickyActive && 'sticky-color'}`}>
                {locale?.toUpperCase()}
              </div>
              <Select
                defaultValue={locale}
                bordered={false}
                options={Languages}
                onChange={(values) => handleChangeLang(values)}
              />
            </Space> */}

            <div
              onClick={() => {
                setShowSidebar(!showSidebar);
              }}
              className={`hamburger-menu ${'sticky-color'}`}
            >
              <SvgIcon
                name={`${showSidebar ? 'icon-close' : 'menu-hamburger'}`}
              />
            </div>
          </div>
        </div>
      </LayoutCommon>
      {/* {!stickyActive && <div className='line-bottom' />} */}
    </MenuWrapper>
  );
};

export default MenuTop;
