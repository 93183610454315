import styled from 'styled-components';

export const JobDetailWrapper = styled.div`
  padding-bottom: 150px;
  .bg-top {
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 200px;
    text-align: center;
  }
  .text-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */

    color: #ffffff;
  }

  .header {
    /* padding-top: 120px; */
    text-align: center;
  }
`;
export const HowToApplyBox = styled.div`
  .info-box {
    background: #e6f7ff;
    /* Primary/2 */

    border: 1px solid #bae7ff;
    border-radius: 10px;
    padding: 17px;
    margin-top: 24px;

    div {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;

      color: #000000;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: #000000;
    }
  }

  .section {
    padding-bottom: 24px;
  }

  .section:last-child {
    padding: 0;
  }
`;
