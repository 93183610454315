import React from 'react';
import { CompanyBranchArrow, CompanyBranchWrapper } from '../styled';
import { LayoutCommon } from '../../../styles/genaralStyled';
import { Col, Image, Row } from 'antd';
import { ICON_ADDRESS, ICON_PHONE } from '~/assets/svgs/icon';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { MailOutlined } from '@ant-design/icons';

const CompanyBranch = (props) => {
  const { t } = props;

  const datas = [
    {
      id: '1',
      icon: <ICON_ADDRESS />,
      title: t('aboutUs.companyBranch.address')
      // des: "2nd Floor, C'land Building, No.81 Le Duc Tho, My Dinh 2 Ward, Nam Tu Liem District, Ha Noi, Viet Nam"
    },
    {
      id: '2',
      icon: <ICON_PHONE />,
      title: t('aboutUs.companyBranch.callUs'),
      des: ''
    }
  ];
  return (
    <LayoutCommon>
      <CompanyBranchArrow>
        <CompanyBranchWrapper>
          <Row gutter={24} className='w-full'>
            <Col xl={12} md={12} sm={12} xs={24}>
              <div className='h-full w-full p-[20px] border-none shadow-md rounded-lg'>
                <div className='flex mb-3'>
                  <img
                    className='mr-10'
                    src={process.env.PUBLIC_URL + '/images/VN.png'}
                    width={24}
                    alt=''
                  />
                  <div className='font-semibold'>
                    {t('aboutUs.companyBranch.title')}
                  </div>
                </div>

                {datas?.map((data, i) => (
                  <div
                    key={data?.id}
                    className='flex cursor-pointer mb-4 items-center'
                  >
                    <div
                      className={`flex items-center justify-center bg-[#FFF3EE] ${
                        i > 0 ? 'w-[40px] ' : 'w-[48.2px] '
                      } h-[40px] about-us-icon`}
                      style={{ borderRadius: '50%' }}
                    >
                      {data?.icon}
                    </div>
                    <div className='ml-3'>
                      <div className='text-gray-600 font-medium text-base'>
                        {data?.title}
                      </div>
                      <div className='font-semibold'>{data?.des}</div>
                    </div>
                  </div>
                ))}

                <div className='flex cursor-pointer mb-4 items-center'>
                  <div
                    className={`flex items-center justify-center bg-[#FFF3EE] w-[40px] h-[40px]`}
                    style={{ borderRadius: '50%' }}
                  >
                    <MailOutlined style={{ color: '#FF5400' }} />
                  </div>
                  <div className='ml-3'>
                    <div className='text-gray-600 font-medium text-base'>
                      {t('aboutUs.companyBranch.email')}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={12} md={12} sm={12} xs={24}>
              <div className='google-map-code w-full shadow-md rounded-lg'>
                <iframe
                  title='myFrame'
                  className='mapFrame'
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.06364557377!2d105.76449488624182!3d21.030139237774975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31345553f18f1393%3A0x8743505c9abe2cb!2s1bitlab%20Technology%20JSC!5e0!3m2!1svi!2s!4v1696327688932!5m2!1svi!2s'
                  style={{
                    border: 0,
                    height: 280,
                    borderRadius: 10,
                    width: '100%'
                  }}
                  allowFullScreen=''
                  loading='lazy'
                  referrerPolicy='no-referrer-when-downgrade'
                ></iframe>
              </div>
            </Col>
          </Row>
        </CompanyBranchWrapper>
        <div className='p-10'>
          <Splide
            className='mb-8'
            aria-label='My Favorite Images'
            options={{
              width: '100%',
              arrows: true,
              type: 'loop',
              pagination: true,
              lazyLoad: true,
              perPage: 4,
              gap: 10,
              breakpoints: {
                1000: {
                  perPage: 2
                },
                600: {
                  perPage: 1
                }
              }
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8]?.map((data) => (
              <SplideSlide key={data?.toString()}>
                <div
                  className='flex items-center w-full'
                  style={{
                    borderRadius: 10,
                    objectFit: 'contain'
                  }}
                >
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/aboutUs/InfoCompany/${data}.jpg`}
                    className='!w-full !h-[280px] sm:!h-[200px]'
                    style={{ borderRadius: 10 }}
                    alt=''
                  />
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </CompanyBranchArrow>
    </LayoutCommon>
  );
};

export default CompanyBranch;
