import React from 'react';
import { NewsDetailItemBox } from '../styled';
import { Col, Row } from 'antd';
import { data } from '../data';
import { useNavigate } from 'react-router-dom';

const NewsDetailItem = () => {
  const navigate = useNavigate();
  return (
    <NewsDetailItemBox>
      <img
        src={process.env.PUBLIC_URL + '/images/explore/newDetail/newDetail.png'}
        alt=''
        width={'100%'}
      />

      <div className='relevant-header'>Relevant Post</div>
      <Row gutter={24}>
        {data.map((item, idx) => {
          return (
            <Col
              className='cp'
              span={8}
              key={`relevant-${idx + 1}`}
              onClick={() => {
                navigate(`/explore-news/relevant-${idx + 1}`);
              }}
            >
              <div className='col-relavant'>
                <img alt='' src={item.image} width={'100%'} />
                <div className='flex text'>
                  <div className='text-1'>{item.domain}</div>
                  <div className='text-2'>{item.time}</div>
                </div>
                <div className='text-3'>{item.title}</div>
              </div>
            </Col>
          );
        })}
      </Row>
    </NewsDetailItemBox>
  );
};

export default NewsDetailItem;
