import React from 'react';
import { CareerWrapper } from './styled';
import { LayoutCommon } from '~/styles/genaralStyled';
import { Col, Row } from 'antd';
import ContactUs from './components/ContactUs';
import JobDescription from './components/JobDescription';

const Career = () => {
  return (
    <CareerWrapper>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/aboutUs/bg-about-us.png)`
        }}
        className='bg-top'
      >
        <LayoutCommon>
          <div className='header'>Career</div>
        </LayoutCommon>
      </div>
      <div style={{ backgroundColor: '#F2F8FB' }}>
        <LayoutCommon>
          <Row
            gutter={24}
            style={{ paddingTop: '40px' }}
            className='flex flex-col-reverse md:flex-row'
          >
            <Col xl={17} md={17} sm={24} xs={24}>
              <JobDescription />
            </Col>
            <Col xl={7} md={7} sm={24} xs={24}>
              <ContactUs />
            </Col>
          </Row>
        </LayoutCommon>
      </div>
    </CareerWrapper>
  );
};

export default Career;
