import styled from 'styled-components';

export const LayoutCommon = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  .main-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
  }

  //Ipad

  @media screen and (min-width: 768px) {
    .main-title {
      font-weight: 700;
      font-size: 36px;
    }
  }

  @media screen and (min-width: 1280px) {
    padding: 0;
  }
`;
// export const ArrowContainer = styled.div`
//   .splide__arrow {
//     background-color: #ffffff;
//     color: #333333;
//     border: 2px solid #333333;
//     border-radius: 50%;
//     padding: 10px;
//     margin-top: -80px;
//   }

//   .splide__arrow:hover {
//     background-color: #ff5300;
//     color: #fff;
//   }
// `;
