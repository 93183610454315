import React from 'react';

const Map = () => {
  return (
    <div style={{ width: '100%' }}>
      <div className='google-map-code'>
        <iframe
          title='myFrame'
          className='mapFrame'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1862.0319785677748!2d105.76808906497902!3d21.030126769970575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3134553d0c654b9d%3A0x53673de329b2b10d!2sC&#39;Land%20Building!5e0!3m2!1svi!2s!4v1686638273641!5m2!1svi!2s'
          style={{ border: 0 }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </div>
    </div>
  );
};
export { Map };
