import {
  ICON_AI_ML,
  ICON_BACKEND,
  ICON_BLOCKCHAIN,
  ICON_CLOUD,
  ICON_CUSTOM_INTEGRATIONS,
  ICON_DEVOPS,
  ICON_DIGITAL_TRANSFORMATION,
  ICON_ENTERPRISE_CONSULTING,
  ICON_FRONTEND,
  ICON_QA,
  ICON_UIUX
} from '~/assets';

export const dataTechnologyBox = [
  {
    img: ICON_BACKEND,
    title: 'Back-end',
    des: 'Seamlessly power your applications with our robust back-end solutions tailored for optimal functionality and performance.'
  },
  {
    img: ICON_FRONTEND,
    title: 'Front-end',
    des: 'Craft captivating user experiences with our front-end expertise, translating designs into intuitive interfaces that captivate & engage.'
  },
  {
    img: ICON_CUSTOM_INTEGRATIONS,
    title: 'Custom Integrations',
    des: 'Integrate diverse systems flawlessly, enhancing efficiency and connectivity across your tech ecosystem.'
  },
  {
    img: ICON_DEVOPS,
    title: 'DevOps',
    des: 'Streamline development and deployment processes, ensuring rapid and reliable delivery of software solutions.'
  },
  {
    img: ICON_CLOUD,
    title: 'Cloud',
    des: 'Elevate your operations with cloud solutions, providing scalable infrastructure and enhanced accessibility for your applications.'
  },
  {
    img: ICON_UIUX,
    title: 'UI/UX',
    des: 'Elevate user engagement through captivating UI/UX design, optimizing interactions and leaving lasting impressions.'
  },
  {
    img: ICON_QA,
    title: 'QA & Testing',
    des: 'Ensure software reliability and quality through rigorous testing, guaranteeing flawless performance across diverse scenarios.'
  },
  // {
  //   img: ICON_BUSINESS_INTELLIGENCE,
  //   title: 'Business Intelligence',
  //   des: 'Harness data-driven insights to fuel informed decision-making and drive strategic growth.'
  // },
  // {
  //   img: ICON_DATA_SCIENCE,
  //   title: 'Data Science',
  //   des: 'Uncover patterns and trends within your data, extracting valuable insights that shape informed business strategies.'
  // },
  {
    img: ICON_AI_ML,
    title: 'AI & ML',
    des: 'Harness the power of artificial intelligence and machine learning to automate tasks, analyze complex data, and fuel predictive capabilities.'
  },
  {
    img: ICON_BLOCKCHAIN,
    title: 'Blockchain',
    des: 'Foster transparency and security in your operations with blockchain solutions, ensuring trust and traceability.'
  },
  {
    img: ICON_ENTERPRISE_CONSULTING,
    title: 'Enterprise Consulting',
    des: 'Benefit from expert advisory to navigate challenges, optimize processes, and align technology with your business objectives.'
  },
  {
    img: ICON_DIGITAL_TRANSFORMATION,
    title: 'Digital Transformation',
    des: 'Embark on a transformative journey, reshaping your organization through innovative technologies and strategic change management.'
  }
];
