export const dataPortfolio = [
  {
    title: 'Mobile Development',
    childrend: [
      {
        titlePage: 'Mobile Development',
        title: 'Learning App',
        img:
          process.env.PUBLIC_URL +
          '/images/template/MobileDevelopment/beauty.png',
        des: "This is a learning application that allows administrators and schools to add review questions, send review tests and exams nationwide. Students' exam results will be reflected in statistics, ranked within schools and individually. This is an app developed for those aiming to prepare for the national cosmetology exam. ",
        mainFeature: [
          'AI-driven questions: Questions are asked based on your knowledge acquisition level.',
          'Select from fields: Select the field you want to strengthen and challenge only the questions in that field.',
          'Mock exam/small test: Take quizzes given by your teacher, including mock exams and lesson reviews.'
        ],
        industry: 'Education',
        technology: ['ReactJS', 'GraphQL'],
        customer: 'A Japanese SI company',
        results: [
          "1bitlab efficiently developed and launched the app within three months, earning positive feedback from satisfied users and customers who appreciated our capability and approach. The application has maintained smooth operation without major errors, demonstrating 1bitlab's commitment to a reliable and enduring solution."
        ],
        developmentscale: '4 months',
        projectscale: '20MM',
        developmentscaleTitle: 'Project Duration',
        projectscaleTitle: 'Project Scale'
      },
      {
        title: 'Korean Learning App',
        img:
          process.env.PUBLIC_URL +
          '/images/template/MobileDevelopment/Funpik.png',
        titlePage: 'Mobile Development',
        des: 'This is a Korean language learning and exam preparation application, stands out as a meticulously designed platform following the TOPIK (Test of Proficiency in Korean) standards. Tailored for learners aiming to excel in the Korean language, this app goes beyond traditional study methods.',
        mainFeature: [
          'TOPIK Alignment: This app aligns with official TOPIK standards for a reliable test representation.',
          'Vocabulary Enhancement: The app strengthens Korean vocabulary through diverse exercises.',
          'Grammar Mastery: This app aids in mastering Korean grammar with targeted lessons.',
          'User-Friendly Interface: It ensures an intuitive interface for a seamless learning journey.'
        ],
        results: [
          "1bitlab demonstrated remarkable dedication, completing the app within a swift three-month timeframe. Upon launch, users warmly embraced the application, expressing high satisfaction with 1bitlab's capabilities and work ethic. Impressively, the app has maintained excellent functionality post-launch, operating smoothly without significant errors that could inconvenience users. This success reflects both the efficiency of 1bitlab's development process and their commitment to delivering a reliable and user-friendly application experience."
        ],
        industry: 'Education',
        technology: ['Swift', 'Kotlin'],
        customer: 'A Korean company',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'Exam Preparation App',
        img:
          process.env.PUBLIC_URL +
          '/images/template/MobileDevelopment/junsei.png',
        titlePage: 'Mobile Development',
        des: 'This is a specialized learning app designed for aspiring Judo therapists seeking qualification. Access past exam questions conveniently through your phone, allowing flexible study sessions at your convenience. Additionally, benefit from the video learning feature for enhanced visualization, improving your study experience.',
        mainFeature: [
          'Specialized Judo Therapy Learning: Tailored content for aspiring Judo therapists preparing for qualification exams.',
          'Convenient Access: Easily access past exam questions on your phone for flexible study sessions.',
          'Video Learning: Enhance your study experience with video learning for better visualization.'
        ],
        results: [
          "Our seamless collaboration, expertise, and dedication ensured the timely delivery of an outstanding app. The app's success post-launch, with over 10,000 downloads in a short time and positive user feedback, underscores 1bitlab's commitment to excellence in app development and customer satisfaction."
        ],
        industry: 'Education',
        technology: ['ReactJS', 'NodeJs', 'Flutter'],
        customer: 'A Japanese SI company',
        developmentscale: '4 months',
        developmentscaleTitle: 'Project Duration',
        projectscale: '12MM',
        projectscaleTitle: 'Project Scale'
      },
      {
        title: 'Social Skill Learning App',
        img:
          process.env.PUBLIC_URL +
          '/images/template/MobileDevelopment/LMS_bridge.png',
        titlePage: 'Mobile Development',
        des: [
          'This is a specialized app designed for special needs schools, focusing on enhancing social skills crucial for managing interpersonal relationships and group activities in various aspects of school, social, and daily life.',
          "The app offers Q&A training across 13 categories and short educational videos, allowing learners to independently and enjoyably improve their social skills. This learning app features training for subjects of the learner's choice and video learning (in 2D &360°). Moreover, students can review their learning status in My page window. "
        ],
        mainFeature: [
          'Training (study by subject).',
          'Learn with videos: watch 2D and 360° videos .',
          "My page: view students' learning status.",
          'Contains over 200 original and friendly illustrations.'
        ],
        results: [
          'Supports imagining and reminiscing about various situations such as home, school, and work. The learning app received numerous positive user reviews, and its launch encountered minimal issues. 1BITLAB team dedicated considerable effort to complete the project within a mere 3-month timeframe, ensuring complete customer satisfaction.'
        ],
        industry: 'Education',
        technology: ['ReactJS', 'NodeJs', 'Flutter', 'ChatGPT'],
        customer: 'A Japanese SI company',
        developmentscale: '3 months',
        developmentscaleTitle: 'Project Duration',
        projectscale: '20MM',
        projectscaleTitle: 'Project Scale'
      },
      {
        title: 'Surgery Learning App',
        img:
          process.env.PUBLIC_URL +
          '/images/template/MobileDevelopment/LMS_Mobile.png',
        titlePage: 'Mobile Development',
        des: "This is an anatomy learning app that includes about 2000 questions covering various topics based on students' abilities. The app uses an algorithm to repeat questions that students frequently get wrong more frequently. Additionally, the app includes 3D images and animations to enhance the learning experience.",
        mainFeature: [
          'Study Modes: Various study modes, including traditional flashcards, learn, write, spell, and test, cater to different learning preferences.',
          "Large Library:  About 2000 questions covering various topics based on students' abilities",
          "Progress Tracking: The platform tracks students' progress, providing insights into areas that may need further review.",
          'Featuring 3D images and animations, the app provides a visually engaging and comprehensive anatomy learning experience.'
        ],
        results: [
          'Through five months of moderately coordinated team efforts, we successfully launched the app, which swiftly garnered an impressive 100,000+ downloads. Throughout its usage, no significant issues surfaced, and the app received complete satisfaction from both customers and users.'
        ],
        industry: 'Education',
        technology: ['Unity', 'NodeJs', 'Flutter'],
        customer: 'A Japanese SI company',
        developmentscale: '6 months',
        developmentscaleTitle: 'Project Duration',
        projectscale: '15MM',
        projectscaleTitle: 'Project Scale'
      },
      {
        title: 'Insurance App',
        img:
          process.env.PUBLIC_URL +
          '/images/template/MobileDevelopment/Pilates_Insutance.png',
        titlePage: 'Mobile Development',
        des: 'This app provides insurance coverage for PSA members, offering protection against accidents or injuries during instruction or machine usage. The app facilitates new memberships, renewals, accident reporting, and offers safety tips for instructors.  ',
        mainFeature: [
          'Comprehensive Insurance Services: Provides insurance coverage and information related to Pilates instruction accidents and machine-related incidents.',
          'Policy Management: Allowing users to view, manage, and renew their insurance policies directly within the app, including policy details, coverage, and expiration dates.',
          'Claims Processing: Enabling users to file insurance claims digitally by submitting necessary documents, photos, and information through the app for faster processing.'
        ],
        results: [
          '1bitlab consistently demonstrates dedication and commitment in their efforts, ensuring a meticulous approach and unwavering focus on project success. Our team invests substantial time and expertise, maintaining meticulous attention to detail at every project phase.'
        ],
        industry: 'Insurance',
        technology: ['ReactJS', 'NodeJs', 'Flutter', 'ChatGPT'],
        customer: 'A Japanese SI company',
        developmentscale: '3 months',
        developmentscaleTitle: 'Project Duration',
        projectscale: '21MM',
        projectscaleTitle: 'Project Scale'
      },
      {
        title: 'News Updates App',
        img:
          process.env.PUBLIC_URL +
          '/images/template/MobileDevelopment/Rotary.png',
        titlePage: 'Mobile Development',
        des: 'This app offers real-time information about an International District. Users gain easy access to district-related information, including governor communication, rotaract, and interact.',
        mainFeature: [
          'District Information Hub: Provides real-time updates and vital information related to International Districts.'
        ],
        results: [
          'Users can stay informed about district activities and initiatives, fostering a stronger sense of community involvement.',
          '1bitlab team consistently invested substantial effort and dedication throughout the project, ensuring meticulous attention to detail, timely execution, and comprehensive support. '
        ],
        industry: 'Media & Entertainment',
        technology: ['Flutter', 'PHP'],
        customer: 'A Japanese SI company',
        developmentscale: '1 months',
        developmentscaleTitle: 'Project Duration',
        projectscale: '2MM',
        projectscaleTitle: 'Project Scale'
      },
      {
        title: 'Voice To Text App',
        img:
          process.env.PUBLIC_URL +
          '/images/template/MobileDevelopment/self_messenger.png',
        titlePage: 'Mobile Development',
        des: 'This app allows students to record and convert their voices to text for teacher review. On survey days, students evaluate their progress by listening to their past recordings. Users can also send messages to their future selves to track achievements. Use of the app should comply with teacher instructions, and unauthorized download or use is prohibited.',
        mainFeature: [
          'Voice-to-Text Recording: Students record voices, converted to text for teacher review.',
          'Progress Evaluation: Users assess their development by reviewing past recordings on survey days.',
          'Future Self Messages: Personalized achievement tracking through messages to future selves.',
          'Teacher Compliance: The app ensures usage aligns with teacher instructions; unauthorized access is prohibited.'
        ],
        results: [
          'In just 3 months, employing intricate technologies, 1bitlab developed and launched this app to the market, receiving an impressive reception. Presently, the app boasts over 100,000 downloads. The app has garnered highly positive user feedback, with numerous teachers praising its exceptional performance and utility'
        ],
        industry: 'Education',
        technology: ['Google speech', 'Flutter'],
        customer: 'A Japanese SI company',
        developmentscale: '3 months',
        developmentscaleTitle: 'Project Duration',
        projectscale: '12MM',
        projectscaleTitle: 'Project Scale'
      },
      // {
      //   title: 'Training Machine',
      //   img:
      //     process.env.PUBLIC_URL +
      //     '/images/template/MobileDevelopment/Training_Machine.png',
      //   titlePage: 'Mobile Development',
      //   des: 'This tablet application presents a user-friendly interface for assessing training progress and reviewing performance outcomes conveniently.',
      //   mainFeature: [
      //     'Training Assessment Platform: Offers a user-friendly interface for assessing training progress and reviewing performance outcomes.'
      //   ],
      //   results: [
      //     'Provides users with an accessible and intuitive platform to monitor and evaluate their training progress. A user-friendly interface improves the overall experience of reviewing and tracking performance outcomes. '
      //   ],
      //   industry: 'Healthcare',
      //   technology: ['Flutter', 'Python', 'C++'],
      //   customer: 'A Japanese SI company',
      //   developmentscale: '4 months',
      //   developmentscaleTitle: 'Project Duration',
      //   projectscale: '23MM',
      //   projectscaleTitle: 'Project Scale'
      // }
    ]
  },
  {
    title: 'Web development',
    childrend: [
      {
        title: 'DentalHub System',
        img:
          process.env.PUBLIC_URL +
          '/images/template/WebDevelopment/Asua_Dental_System.png',
        titlePage: 'Web development',
        des: 'Explore a user-friendly web-based clinic system offering detailed clinic information, seamless new patient registration, appointment booking, and exclusive access to personal dental records.',
        mainFeature: [
          'Clinic Information Access: Detailed insights into clinic information for informed patient engagement.',
          'New Patient Registration: Streamlined onboarding for hassle-free registration.',
          'Appointment Booking: Convenient online scheduling for efficient time management.',
          'Personal Dental Record Access: Secure and direct access to personal dental records, enhancing patient transparency.'
        ],
        results: [
          'Following dedicated efforts, 1bitlab successfully completed and deployed the project. The highly user-friendly platform has heightened patient engagement and satisfaction, showcasing the effective integration of technology to enhance the healthcare experience. Notably, users encountered no serious errors or difficulties during use, a source of pride for 1bitlab in delivering a seamless and error-free healthcare solution.'
        ],
        industry: 'Health',
        technology: ['MySQL', 'PHP'],
        customer: 'A Japanese SI company',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'Dental Clinic Platform',
        img:
          process.env.PUBLIC_URL +
          '/images/template/WebDevelopment/Axis_Digital_Aid_System.png',
        titlePage: 'Web development',
        des: 'Access comprehensive information about orthopedic clinics seamlessly through a user-friendly web-based system. This platform not only serves as an informative resource but also facilitates the convenient registration of new patients and the straightforward booking of appointments.',
        mainFeature: [
          'Clinic Information Portal: In-depth insights into orthopedic clinics for informed decision-making.',
          'New Patient Registration: Easy onboarding for new patients, simplifying the registration process.',
          'Appointment Booking: Convenient online scheduling for efficient and accessible appointment management.'
        ],
        results: [
          "After its efforts, 1bitlab completed the project and put it into operation. Simplified registration, scheduling, and record access directly empowered patients, while the user-friendly interface kept them engaged and satisfied. This seamless, error-free technological integration is a testament to 1bitlab's dedication to revolutionizing healthcare for the better."
        ],
        industry: 'Health',
        technology: ['MySQL', 'PHP'],
        customer: 'A Japanese SI company',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'Learning Web',
        img:
          process.env.PUBLIC_URL + '/images/template/WebDevelopment/beauty.png',
        titlePage: 'Web development',
        des: "This is a learning website that allows administrators and schools to add review questions, send review tests and exams nationwide. Students' exam results will be reflected in statistics, ranked within schools and individually. This is an web developed for those aiming to prepare for the national cosmetology exam. ",
        mainFeature: [
          'AI-driven questions: Questions are asked based on your knowledge acquisition level.',
          'Select from fields: Select the field you want to strengthen and challenge only the questions in that field.',
          'Mock exam/small test: Take quizzes given by your teacher, including mock exams and lesson reviews.'
        ],
        results: [
          "Following focused efforts, 1bitlab successfully launched the website, providing valuable features that greatly assist students in their study and exam processes. The platform continues to meet user requirements seamlessly, offering a reliable and problem-free experience during use. This successful implementation underscores 1bitlab's commitment to delivering a functional and beneficial learning platform for students."
        ],
        industry: 'Education',
        technology: ['ReactJS', 'GraphQL'],
        customer: 'A Japanese SI company',
        developmentscale: '4 months',
        developmentscaleTitle: 'Project Duration',
        projectscale: '20MM',
        projectscaleTitle: 'Project Scale'
      },
      {
        title: 'Wedding Booking',
        img:
          process.env.PUBLIC_URL + '/images/template/WebDevelopment/Bridal.png',
        titlePage: 'Web development',
        des: 'This is a user-friendly website simplifying wedding planning in Japan. Users can effortlessly book venues, organize guest lists, manage gift registries, and coordinate every aspect of their special day. Bridal aims to offer a convenient and comprehensive solution for couples looking to celebrate their love in Japan.',
        mainFeature: [
          'Venue Booking: Easily book diverse wedding venues in Japan.',
          'Guest Arrangement: Streamline guest lists, invitations, and RSVPs.',
          'Gift Registry: Manage and share gift registries for a hassle-free gifting experience.',
          'Event Coordination: Coordinate schedules, seating, and special requests.',
          'Customizable Packages: Explore flexible wedding packages based on preferences and budget.',
          'Vendor Connections: Connect with trusted vendors for various wedding services.',
          'Budget Management: Track expenses with budget management tools.'
        ],
        results: [
          "1bitlab's dedication has perfected Bridal, surpassing customer expectations. Rapid success post-launch includes numerous weddings facilitated in Japan. Users praise the user-friendly interface and effective coordination tools, driving high satisfaction and motivation for 1bitlab's continued excellence."
        ],
        industry: 'Media & Entertainment',
        technology: ['Vuejs', 'ASP.NET'],
        customer: 'A Japanese SI company',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'Study System',
        img: process.env.PUBLIC_URL + '/images/template/WebDevelopment/EMI.png',
        titlePage: 'Web development',
        des: "Our website is a versatile platform designed to revolutionize how users engage with learning. It facilitates an array of functionalities, including online lessons, video-based learning, progress tracking via 'My Page,' comprehension assessments through quizzes, and streamlined access to school announcements via the bulletin board.",
        mainFeature: [
          'Participate in live online lessons.',
          'Engage in video-based learning modules.',
          "Track personal progress via 'My Page'.",
          'Assess understanding through quizzes.',
          'Access school announcements on the bulletin board.'
        ],
        results: [
          'Our learning web got more than 5,000 traffics after it started. People like it because it helps them learn in different ways, all in one place. It saves time and helps users learn better. People said they really like how easy it is to use and how much time it saves them.'
        ],
        industry: 'Education',
        technology: ['Flutter', 'ReactJs', 'NestJs'],
        customer: 'A Japanese SI company',
        developmentscale: '5 months',
        developmentscaleTitle: 'Project Duration',
        projectscale: '25MM',
        projectscaleTitle: 'Project Scale'
      },
      {
        title: 'ERPS System',
        img:
          process.env.PUBLIC_URL +
          '/images/template/WebDevelopment/ERPS_System.png',
        titlePage: 'Web development',
        des: "Elevate security in your operations with our cloud-based ERP system's developed security functions. It ensures rule adherence, receive real-time notifications, and export dashboards seamlessly.",
        mainFeature: [
          'Security Rule Enforcement: The system actively controls user compliance with security rules, fortifying the protection of sensitive data and processes.',
          'Notification System: Comprehensive notification controls are in place, alerting users and administrators to security-related events and updates in real-time.',
          'Dashboard Export: Empower users and administrators by allowing seamless export of dashboards, facilitating data analysis and reporting.'
        ],
        results: [
          'Implementation of these features ensures robust security rule enforcement, real-time notifications, and efficient dashboard exports. The system promotes a secure, informed, and streamlined cloud-based ERP environment.'
        ],
        industry: 'Business',
        technology: ['C++', 'Srping Boot', 'Azure', 'WinForms'],
        customer: 'A Japanese SI company',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'Wedding Photo Spots Site',
        img:
          process.env.PUBLIC_URL +
          '/images/template/WebDevelopment/Photowedding.png',
        titlePage: 'Web development',
        des: 'This is a website introducing wedding photography locations in Japan. The studios will post photos and details of their photography packages (including location information, accompanying makeup services, costs, time...). Users can book a photo session with the studio. After taking photos, the studio will send photos and videos to the user via mobile app.',
        mainFeature: [
          'Comprehensive Studio Listings: Studios showcase their photography packages with location details, makeup services, costs, and session duration.',
          'Booking Functionality: Users can easily book their desired photo session with their chosen studio through the website.',
          'Mobile App Integration: After the photo session, studios send captured photos and videos directly to the user via a mobile app for convenient access.'
        ],
        results: [
          "Provide a seamless experience for users seeking wedding photography locations in Japan. The platform's comprehensive listings have facilitated numerous successful bookings between users and studios. The integration of the mobile app has significantly improved user convenience, allowing quick and easy access to the photos and videos captured during the photo session."
        ],
        industry: 'Media & Entertainment',
        technology: ['VueJS', 'Vuetify', 'AWS Amplify', 'Flutter'],
        customer: 'A Japanese SI company',
        developmentscale: '9 months',
        developmentscaleTitle: 'Project Duration',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'Workspace Rental Platform',
        img:
          process.env.PUBLIC_URL +
          '/images/template/WebDevelopment/Remote_Office.png',
        titlePage: 'Web development',
        des: 'This website provides an easy-to-use web platform for clients to register, host, and edit office space information available for rent. Users can conveniently search for co-spaces based on location, date, and other specific details, make reservations, and complete payments via the GMO payment gateway.',
        mainFeature: [
          'Effortless Rental Process: Clients can easily register and manage office space details, while users can seamlessly search, reserve, and pay for co-spaces online.'
        ],
        results: [
          "Streamlined Rental Management: The platform's user-friendly interface simplifies office space rental management, facilitating swift bookings and payments.",
          'Convenient Access: Users benefit from a hassle-free search and booking process, enhancing their experience when seeking co-working spaces.'
        ],
        industry: 'Booking',
        technology: ['PHP', 'Wordpress', 'Angular'],
        customer: 'A Japanese SI company',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'Invoice Management Tools',
        img:
          process.env.PUBLIC_URL +
          '/images/template/WebDevelopment/SAP_Bydesign.png',
        titlePage: 'Web development',
        des: "This software focuses on streamlining the import/export invoice process using Excel's Macro feature, providing various invoice templates. It receives highly useful client feedback for its time-saving nature and user-friendly interface.",
        mainFeature: [
          'Invoice optimization using Excel.',
          'Multiple invoice templates.'
        ],
        results: [
          "This website's efficient invoice management tools have resulted in time savings and streamlined processes, commended by satisfied clients. It receives highly valuable feedback for its ease of use and time-saving features in managing invoices."
        ],
        industry: 'Retails & E-commerce',
        technology: ['Javascript', 'VBA', 'Adobe Designer'],
        customer: 'A Japanese SI company',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      }
    ]
  },
  {
    title: 'AI Application',
    childrend: [
      {
        titlePage: 'AI Application',
        title: 'Moderator AI',
        img:
          process.env.PUBLIC_URL +
          '/images/template/AlApplication/ai_chatbot.png',
        des: 'The Chatbot demo integrates easily into various platforms like websites and social media, optimizing customer service for businesses by efficiently addressing customer inquiries and providing information 24/7.',
        mainFeature: [
          'Multi-Platform Integration: Easily integrates into websites and social media for efficient customer service.',
          '24/7 Support: Offers continuous support to address customer queries and provide information.'
        ],
        industry: 'Retails & Commerce',
        technology: ['Python', 'ChatGPT'],
        customer: 'A Japanese SI company',
        results: [
          'Cost-Efficiency: Chatbots reduce operational costs compared to hiring customer service representatives, as they do not require salaries or benefits.',
          'Enhanced Effectiveness: Chatbots can swiftly handle multiple conversations simultaneously, increasing efficiency and reducing response times.',
          'Continuous Customer Support: Chatbots provide round-the-clock support without the need for breaks, enhancing the customer experience.',
          'Increased Interaction and Marketing: They can suggest products, services, or useful information to improve engagement and marketing efforts.'
        ],
        developmentscale: '',
        projectscale: '',
        developmentscaleTitle: '',
        projectscaleTitle: ''
      },
      {
        title: 'Document Maker',
        titlePage: 'AI Application',
        img:
          process.env.PUBLIC_URL +
          '/images/template/AlApplication/document_maker.png',
        des: 'This is a versatile application proficient in parsing documents into text and Excel files, adept at recognizing handwritten text. Capable of importing images and PDFs, it offers extensive document compatibility. Its training mode facilitates learning new invoice formats for automatic recognition and extraction, consistently contributing to heightened customer satisfaction.',
        mainFeature: [
          'Document Parsing & Transformation: Converts documents into text and Excel files, recognizing handwritten text and offering extensive compatibility.'
        ],

        industry: 'Technology',
        technology: ['Python', 'Tensorflow', 'CRAFT'],
        customer: 'A Japanese SI company',
        results: [
          "The software's versatile document parsing and learning capabilities contribute to consistently positive user experiences. 1BITLAB team's dedicated effort is evident through their proactive problem-solving, timely execution, and comprehensive support, ultimately leading to successful project outcomes and client satisfaction."
        ],
        developmentscale: '',
        projectscale: '',
        developmentscaleTitle: '',
        projectscaleTitle: ''
      }
    ]
  },
  {
    title: 'AR/VR Application',
    childrend: [
      {
        title: 'Internal Body Functions Learning',
        titlePage: 'AR/VR Application',
        img:
          process.env.PUBLIC_URL +
          '/images/template/ARVRApplication/AR_BOOK.png',
        des: 'This is a digital platform allowing users to explore the inner workings of the stomach, brain, etc., through character conversations presented via Augmented Reality (AR).',
        mainFeature: [
          'Conversational AR for internal bodily functions',
          'Detailed understanding through character conversations'
        ],

        industry: 'Education',
        technology: ['ReactJS', 'ThreeJs', 'Unity'],
        customer: 'A Japanese SI company',
        results: [
          'Since its launch, there have been no significant issues, and it has been highly popular. With persistent efforts and intricate technologies, we have continuously strived to refine our product, ensuring user satisfaction.'
        ],
        developmentscale: '2 months',
        developmentscaleTitle: 'Project Duration',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'Surgery Learning App',
        titlePage: 'AR/VR Application',
        img:
          process.env.PUBLIC_URL + '/images/template/ARVRApplication/LMS.png',
        des: 'This is an anatomy learning app featuring 3D images and animations with over 2000 questions catering to different student abilities. The app adapts by repeating challenging questions, resulting in over 100,000 downloads and numerous positive user feedbacks praising its usefulness.',
        mainFeature: [
          'Extensive anatomy-related questions',
          'Adaptive learning with 3D visuals'
        ],
        results: [
          "Achieved over 100,000 downloads and garnered numerous positive user feedbacks, highlighting its usefulness in anatomy education. The app's adaptability and comprehensive content have led to substantial user adoption and positive feedback, affirming its value in anatomy education."
        ],
        industry: 'Education',
        technology: ['Unity', 'Flutter', 'NodeJs'],
        customer: 'A Japanese SI company',
        developmentscale: '6 months',
        developmentscaleTitle: 'Project Duration',
        projectscale: '15MM',
        projectscaleTitle: 'Project Scale'
      }
    ]
  },
  {
    title: 'Game Development',
    childrend: [
      {
        title: 'Metaverse game',
        img: process.env.PUBLIC_URL + '/images/template/Game/Howlcity.png',
        titlePage: 'Game Development',
        des: 'This is a metaverse game leveraging blockchain technology, aiming to provide next-generation gaming experiences. It offers account management, history tracking, reconnection mechanisms, and daily rewards for top players.',
        mainFeature: [
          'Metaverse gaming with account management',
          'Daily rankings and awards',
          'Cheating prevention measures'
        ],
        results: [
          'Complex Game Development: Successfully delivers a complex metaverse game with stable performance and preventative measures against cheating in the game.',
          'This game offers immersive gaming experiences, albeit challenging to develop, ensuring fair gameplay and rewarding top performers, leading to an engaging user base.'
        ],
        industry: 'Media & Entertainment',
        technology: ['PHP', 'MySQL'],
        customer: 'A Japanese SI company',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'DeFix NFT game',
        img: process.env.PUBLIC_URL + '/images/template/Game/hydra.png',
        titlePage: 'Game Development',
        des: 'This is a P2E Metaverse featuring 3D NFT Dragons. Earn tokens by training, trading, staking, winning races, or betting, offering users diverse financial opportunities within the digital dragon ecosystem.',
        mainFeature: [
          'Dragon Creation and Training.',
          'Play-to-Earn Opportunities.',
          'Participate in races to earn rewards.',
          'Rent out dragons or showcase them in an exhibition park to generate in-game value.',
          'Engage in breeding, training, racing, renting, and betting.',
          'Participate in the annual Cup to showcase your dragon racing skills.',
          'Collect dragon balls to contribute to the revival of the land.'
        ],
        results: [
          "1bitlab's substantial effort has been pivotal in ensuring customer satisfaction and a positive user experience within this game. The metaverse seamlessly integrates traditional racing with NFTs, emphasizing strong player-dragon connections. "
        ],
        industry: 'Media & Entertainment',
        technology: ['Mongo DB', 'Nodejs', 'Solidity', 'Web3.js', 'ReactJS'],
        customer: 'A Japanese SI company',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'DeFix NFT game',
        img: process.env.PUBLIC_URL + '/images/template/Game/mymas.png',
        titlePage: 'Game Development',
        des: 'Introducing DeFix, an NFT gaming ecosystem merging blockchain technology, featuring Free Play to Earn and a Staking model. Players mine tokens using NFT Lands and Machines, complete quests with the NFT Guardian Card, access daily mini-games, and upgrade NFTs in the Training Arena. The platform also facilitates seamless NFT trading among players.',
        mainFeature: [
          'Free Play to Earn and Staking model.',
          'Token mining with NFT Lands and Machines.',
          'Quest completion with the NFT Guardian Card.',
          'Daily mini-games and NFT upgrades in the Training Arena.',
          'Easy NFT trading among players.'
        ],
        results: [
          'Since its web game system launch, DeFix has maintained high security, stable performance, and rapidly attracted over 10,000 new gamers with 10,000+ downloads in a short time.'
        ],
        industry: 'Media & Entertainment',
        technology: ['Mongo DB', 'Nodejs', 'Solidity', 'Web3.js', 'ReactJS'],
        customer: 'A Japanese SI company',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'NFT Marketplace',
        img: process.env.PUBLIC_URL + '/images/template/Game/Ludena.png',
        titlePage: 'Game Development',
        des: 'This is an innovative gaming ecosystem through its marketplace. This blockchain-integrated platform revolutionizes in-game asset trading, allowing users to buy, sell, and trade items securely and seamlessly.',
        mainFeature: [
          'Blockchain Integration: Ensure transparent and secure transactions with blockchain technology.',
          'In-Game Asset Trading: Dynamically participate in the player-driven economy by trading in-game assets.',
          'LDN Token Transactions: Utilize the native LDN token for streamlined and unified transactions.',
          'User-Friendly Interface: Enjoy an intuitive platform designed for easy navigation and enhanced user experience.'
        ],
        results: [
          "After dedicated efforts, 1bitlab successfully launched the platform's marketplace, rapidly enriching the gaming community. Users warmly welcomed the platform, citing its smooth usability and error-free performance since its launch. This success highlights 1bitlab's commitment to delivering a seamless gaming experience."
        ],
        industry: 'Media & Entertainment',
        technology: ['Solidity', 'ReactJS', 'Nodejs', 'Web3.js'],
        customer: 'Confidental',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'NFT Soccer game',
        img: process.env.PUBLIC_URL + '/images/template/Game/soccerhub.png',
        titlePage: 'Game Development',
        des: 'Experience the inaugural soccer management game, a play-to-earn opportunity for all. Dive into NFT players, a marketplace, staking, farming, and a thrilling betting system, creating a dynamic and rewarding gaming experience.',
        mainFeature: [
          'NFT Players.',
          'NFT Marketplace.',
          'Staking & Farming.',
          'Betting System.'
        ],
        results: [
          'The inaugural soccer management game, offering a play-to-earn opportunity for all, has seen notable success. With features like NFT players, an NFT marketplace, staking and farming, and a betting system, the game has garnered positive feedback and engagement from a diverse player base.'
        ],
        industry: 'Media & Entertainment',
        technology: ['MongoDB', 'NFT', 'Nodejs', 'PHP', 'Laravel'],
        customer: 'A Japanese SI company',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      }
    ]
  },
  {
    title: 'Blockchain Development',
    childrend: [
      {
        title: 'Crypto-currency Exchange',
        titlePage: 'Blockchain Development',
        img:
          process.env.PUBLIC_URL +
          '/images/template/BlockchainDevelopment/Crypto_currency_Exchange.png',
        des: 'An international multi-language cryptocurrency exchange, provide to its user and the entire industry with professional, safe, transparent one-stop services of digital asset exchange. Deposit & withdraw at any time, anywhere.',
        mainFeature: [
          'International multi-language cryptocurrency exchange',
          'Professional, safe, and transparent one-stop services for digital asset exchange',
          'Advanced security features: two-factor authentication, cold wallet storage, regular security audits',
          'Supports a diverse range of cryptocurrencies for extensive trading options'
        ],
        industry: 'Fintech',
        technology: ['PHP', 'Solidity', 'Docker'],
        customer: 'A Japanese SI company',
        results: [
          'Our clients left very positive feedback on top review sites for us. 1BITLAB TEAM finished this project in time, and this wallet has 13.000 users at the moment. 1BITLAB always supports the client 24/7 and solve their issues in real-time, so the number of arising issues went down, and the client was able to reduce their budget for support services by 50%.'
        ],
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'Crypto-currency Wallet',
        titlePage: 'Blockchain Development',
        img:
          process.env.PUBLIC_URL +
          '/images/template/BlockchainDevelopment/Crypto_currency_Wallet.png',
        des: "Our client's goal is to offer a suite of integrated products that make non-fungible tokens (NFTs) easy for individuals, businesses, and developers. A crypto-currency wallet, stores private and public keys and interacts with blockchain to enable users to send and receive digital currency and monitor their balance, transaction history. ",
        mainFeature: [
          'Allow users to log in with blockchain wallets.',
          'Display staking pools with many types of tokens.',
          'Users can stake into a pool to get profits, restake into another pool and claim rewards, or unstake from pools.'
        ],
        industry: 'Fintech',
        technology: ['Solidity', 'Nodejs'],
        customer: 'A Japanese SI company',
        results: [
          'We had to overcome the following challenges to complete this Crypto-currency Wallet:',
          'This is a third-parties tool to allow users to use other digital wallets to connect app.',
          'It is difficult to find out solutions because of lacking references.',
          'After extensive efforts, our public crypto-currency wallet has achieved significant user acceptance and appreciation.'
        ],
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'Decentralized exchange',
        img:
          process.env.PUBLIC_URL +
          '/images/template/BlockchainDevelopment/FalconSwap.png',
        titlePage: 'Blockchain Development',
        des: 'This is a vibrant DEX for crypto trading and earning, its tokens unlock a world of playful memes, innovative NFTs, and exciting games. We aims to make an impact through memes, driving the GameFi ecosystem and Metaverse. Our dynamic NFTs and gaming reflect the soaring spirit of falcons, crafting a joyful community with whimsical memes, NFTs, and games.',
        mainFeature: [
          'Collect & Trade: Dive into a treasure trove of collectible and tradable digital NFT cards.',
          'Metaverse Magic: Immerse yourself in our 2D-3D gaming world, where fantasy meets reality.',
          'Earn Tokens: Play, win, and earn Tokens in a realm where fun meets reward.',
          'Gamefi Integration: Enjoy seamless transactions and connectivity within our ecosystem.',
          'Community at Heart: Be part of a game shaped by its players, rich in educational elements about NFTs and decentralized finance.',
          '1BITLAB joined this project: analyzing, designing, development, testing'
        ],
        industry: 'Media & Entertainment',
        technology: ['ReactJS', 'Web3Js', 'Blockchain (Solidity)', 'Nodejs'],
        customer: 'A Japanese SI company',
        results: [
          'The game development was deployed without any security issues during launch. It features a monitoring system to detect and assist players in case of interruptions. Within 1 week of release, there were over 1,000 users immediately engaged with the game'
        ],
        developmentscale: '3 months',
        developmentscaleTitle: 'Project Duration',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'Global Fandom Network System',
        img:
          process.env.PUBLIC_URL +
          '/images/template/BlockchainDevelopment/FNS_FandomNetworkSystem.png',
        titlePage: 'Blockchain Development',
        des: 'This is the first global fandom network system for idols and fans, built on Blockchain technology. Idols and fans are rewarded by the system coin exchange coin to real money by payment gateways. Using Blockchain ensures transparency and security, setting a new standard for fan-idol interactions in the entertainment industry',
        mainFeature: [
          'This platform rewards both idols and fans with system coins, exchangeable for real money through secure payment gateways. ',
          'Fans actively engaging with their idols earn rewards, fostering a vibrant community while idols benefit from fan engagement, creating a mutually beneficial relationship. '
        ],
        results: [
          '1BITLAB takes charge of the Backend system and Blockchain core module. Only 3 months, 1bitlab team completed their work ahead of schedule, demonstrating seamless cooperation throughout the implementation process and receiving high praise from customers for their exceptional quality of work.'
        ],
        industry: 'Media & Entertainment',
        technology: ['Mongo DB', 'ReactJs', 'Blockchain'],
        customer: 'A Japanese SI company',
        developmentscale: '3 months',
        developmentscaleTitle: 'Project Duration',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'Crypto Wallet',
        img:
          process.env.PUBLIC_URL +
          '/images/template/BlockchainDevelopment/FNS_Wallet.png',
        titlePage: 'Blockchain Development',
        des: 'This app was created to connect idols and fans, focusing on the K-pop industry. It allows easy interaction between fans and idols, providing updates on idol news, live streaming, chatting, and buying and selling NFTs through the wallet.',
        mainFeature: [
          'Simple fan club creation',
          'News updates and schedule management ',
          'Voting and translation options',
          'Live broadcasts by stars',
          'Messaging functions',
          'Buying and selling NFTs',
          'Handle crypto accounts',
          'Perform coin transactions: sending, receiving, view price charts, volume, transaction history',
          'Buy coins via PayPal',
          'Swap coins with the system owner'
        ],
        results: [
          'This app stands out as the first global fandom network system built on Blockchain technology for stars and fans. Our collaboration empowers this app to expand its development team, offering an engaging social platform. The platform is trusted by a large community of young people worldwide, enhancing their involvement in K-pop entertainment and strengthening the bond between idols and their fans.'
        ],
        industry: 'Media & Entertainment',
        technology: [
          'Mongo DB',
          'ReactJs',
          'Blockchain',
          'PHP',
          'Nodejs',
          'MySQL'
        ],
        customer: 'A Japanese SI company',
        developmentscale: '3 months',
        developmentscaleTitle: 'Project Duration',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'Content Collaboration Hub',
        img:
          process.env.PUBLIC_URL +
          '/images/template/BlockchainDevelopment/IPXHOP.png',
        titlePage: 'Blockchain Development',
        des: 'This is a versatile platform offering intellectual property (IP) trading services across music, tickets, characters, art, and more. Explore the thrill of uncovering fresh value in daily life through a diverse range of content available on IP Shop.',
        mainFeature: [
          'Varied Intellectual Property Offerings: Sound Recordings, Art, Literature, Patents, Characters, and Performances',
          'Collaborative Content Creation Support for Creators in Diverse Fields',
          'Free Sharing and Trading of Created Content',
          'Enhancement of Intellectual Property Culture for a Healthy IP Trading Ecosystem'
        ],
        results: [
          "After dedicating considerable time to perfecting its features, we've successfully launched a creativity-focused website. This fully functional application meets diverse user needs, earning high satisfaction and praise from our customers"
        ],
        industry: 'Media & Entertainment',
        technology: ['PHP', 'Solidity', 'Web3.js'],
        customer: 'A Japanese SI company',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'NFT purchasing system',
        img:
          process.env.PUBLIC_URL +
          '/images/template/BlockchainDevelopment/NFT_GOLD.png',
        titlePage: 'Blockchain Development',
        des: 'This is an NFT purchasing system developed by 1BITLAB, exclusively selling NFT items representing gold-based products. Only authorized administrators and verified partners can mint these tokens, which users can purchase using specific tokens issued by the system.',
        mainFeature: [
          'Token Minting: Only authorized administrators or verified partners can create and mint new NFT tokens representing specific products or digital items.',
          'Token Purchase: Users have the ability to purchase these NFT items using specific tokens issued by the system, allowing them to acquire unique digital assets.',
          'Blockchain Verification: Utilizing blockchain technology to ensure the authenticity, ownership, and uniqueness of each NFT item, providing a secure and transparent transaction process.',
          'Marketplace Integration: The system often integrates with marketplaces or platforms where users can trade, sell, or exchange their acquired NFT items with other users.'
        ],
        results: [
          'The system was seamlessly deployed without security issues, featuring a robust monitoring system for swift error detection and player support during service interruptions. Within a mere week of its release, the system attracted over 1,000 users, signaling an encouraging start to its user base growth'
        ],
        industry: 'Fintech',
        technology: ['Solidity', 'ReactJs', 'Nodejs', 'Web3.js'],
        customer: 'A Japanese SI company',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      },
      {
        title: 'NFT Wallet',
        img:
          process.env.PUBLIC_URL +
          '/images/template/BlockchainDevelopment/NFT_Taken.png',
        titlePage: 'Blockchain Development',
        des: 'The app empowers administrators to mint and sell NFTs through the mobile wallet. Users can engage in buying, trading, transferring ownership of NFTs, and claiming airdropped items.',
        mainFeature: [
          'Mint and sell NFTs via mobile wallet',
          'Purchase, trade, transfer ownership of NFTs',
          'Claim airdropped items'
        ],
        results: [
          'Following meticulous refinement to meet customer needs, 1bitlab has successfully launched the application. The app is consistently receiving positive reviews from satisfied users.'
        ],
        industry: 'Fintech',
        technology: ['Mongo DB', 'Nodejs', 'Solidity', 'Web3.js'],
        customer: 'A Japanese SI company',
        developmentscale: '',
        developmentscaleTitle: '',
        projectscale: '',
        projectscaleTitle: ''
      }
    ]
  }
];
