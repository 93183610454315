import styled from 'styled-components';

export const CareerWrapper = styled.div`
  .bg-top {
    height: 400px;
    padding-top: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .header {
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
    padding-top: 120px;
  }
`;

export const JobWrapper = styled.div`
  padding-bottom: 0;
  .col-item {
  }

  .box-item {
    background-color: #fff;
    padding: 12px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
  }

  .tag {
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    border-radius: 40px;
    /* identical to box height */
    text-align: center;

    color: #000000;
    width: 95px;
    height: 26px;
    margin: 16px 12px 12px 0;
    &-1 {
      background: #bae7ff;
    }
    &-2 {
      background: #d9f7be;
    }
  }

  .job-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    color: #000000;
    padding-bottom: 12px;
  }

  .price {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #000000;
    padding-right: 36px;
  }

  @media screen and (min-width: 768px) {
    padding-bottom: 150px;
  }
`;
