import { Splide, SplideSlide } from '@splidejs/react-splide';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from '~/common/hooks/useTranslation';
import { OutPartnersWrapper } from '~/pages/HomePage/styled';
import { LayoutCommon } from '~/styles/genaralStyled';

const OutPartners = () => {
  const { t } = useTranslation();

  const list = [
    '/images/partners/partner3.png',
    '/images/partners/partner2.png',
    '/images/partners/partner1.png',
    '/images/partners/partner4.png',
    '/images/partners/partner5.png',
    '/images/partners/p6.png',
    '/images/partners/p7.png',
    '/images/partners/p8.png',
    '/images/partners/p9.png',
    '/images/partners/p10.png',
    '/images/partners/p11.png',
    '/images/partners/p12.png'
  ];
  return (
    <LayoutCommon key={'OutPartners'}>
      <OutPartnersWrapper>
        <div className='about-info-type-2'>
          {t('homePage.OutPartners.title')}
        </div>
        <div className='todo-title'>{t('homePage.OutPartners.des')}</div>
        <div className='px-10'>
          <Splide
            aria-label='My Favorite Images'
            options={{
              width: '100%',
              arrows: true,
              pagination: false,
              lazyLoad: true,
              perPage: 6,

              gap: 10,
              breakpoints: {
                1000: {
                  perPage: 4
                },
                600: {
                  perPage: 2
                }
              }
            }}
          >
            {list?.map((data) => (
              <SplideSlide key={data}>
                <div className='my-4 h-[100px] flex justify-center items-center rounded-lg shadow-[0_4px_50px_0_rgba(0,0,0,0.10)]'>
                  <img src={data} alt='' />
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </OutPartnersWrapper>
    </LayoutCommon>
  );
};

OutPartners.propTypes = {
  style: PropTypes.object
};

export default OutPartners;
