import { Col, Row } from 'antd';
import React from 'react';
import { data } from '../data';
import SvgIcon from '~/components/SvgIcon';
import { JobWrapper } from '../styled';
import { useNavigate } from 'react-router-dom';
import Contact from '~/pages/ServiceSystem/components/Contact';

const JobDescription = () => {
  const navigate = useNavigate();
  return (
    <JobWrapper>
      <Row gutter={[24, 24]}>
        {data.map((item, idx) => {
          return (
            <Col
              onClick={() => {
                navigate(`/career/${idx + 1}`);
              }}
              className='col-item cp'
              xl={12}
              md={12}
              sm={24}
              xs={24}
              key={`job-${idx + 1}`}
            >
              <div className='box-item'>
                <img src={item.image} width={'100%'} alt=''/>
                <div className='flex'>
                  <div className='tag tag-1'>Front-end</div>
                  <div className='tag tag-2'>Fresher</div>
                </div>
                <div className='job-title'>{item.title}</div>
                <div className='flex'>
                  <div className='flex'>
                    <SvgIcon name='blue-calendar' />
                    <div className='price'>{item.time}</div>
                  </div>
                  <div className='flex'>
                    <SvgIcon name='blue-dollar' />
                    <div className='price'>{item.price}</div>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>

      <div className='block md:hidden'>
        <Contact />
      </div>
    </JobWrapper>
  );
};

export default JobDescription;
